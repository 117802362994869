import { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { InstitutionListResponse } from "../../models/responseData";


const determineResponseType = (response: any): response is InstitutionListResponse[] => true;

const Subsection3 = () => {
    const {
        isAuthenticated,
        getAccessTokenSilently,
    } = useAuth0();
    const [institutionList, setInstitutionList] = useState<InstitutionListResponse[] | []>([]);
    const [institutionListLoading, setInstitutionListLoading] = useState(true);

    useEffect(() => {
        const getInstitutions = async () => {
            try {
                const accessToken = await getAccessTokenSilently();
                const requestUrl = `admin/list_institutions`;
                const { data } = await getProtectedResource(accessToken, requestUrl);
    
                if (data && determineResponseType(data)) {
                    setInstitutionList(data.sort((a: any, b: any) => a.institution.localeCompare(b.institution)));
    
                    setInstitutionListLoading(false);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };

        if (isAuthenticated) {
            getInstitutions();
        }
    }, [isAuthenticated, getAccessTokenSilently])

    return (
        <>
            <h3>Reporting/Analysis</h3>
            <h4 className="italicize-sub-header">Cohort</h4>
            <p>
                Within the <em>Quantitative Report</em>, comparisons between your institution and the “cohort” provide context for your
                results in the broader faculty labor market. The cohort consists of faculty in the eligible sample from institutions
                that have participated in the study since 2019. Institutions in the cohort include:
            </p>
            {institutionListLoading ? <div>Loading institutions...</div> : (
                <ul className="cohort-list">
                    {
                        institutionList.map((institution, i) => (
                            <li key={i}>
                                {institution.institution}
                            </li>
                        ))
                    }
                </ul>
                    )
                }

            <h4 className="italicize-sub-header">Comparative Analysis</h4>
            <p>
                For most analyses, results are disaggregated by employment status (i.e., retention or departure), which we label
                “Overall”, and then separately by gender, race/ethnicity, tenure status, and broad disciplinary categories.
                However, results are omitted whenever a subgroup includes fewer than five respondents. Note that the n of
                respondents may change from subgroup to subgroup depending on the availability of their demographic data.
                In addition, n may appear to change within a group. This typically occurs when we are reporting both the mean
                and the frequency of responses to a Likert-scale item. So, one analysis excludes “Decline to answer” from its
                calculation, while another includes such “missing data” in its reporting.
            </p>

            <h4>Gender</h4>
            <p>
                Although this COACHE survey invites respondents to provide a non-binary gender identification if applicable,
                we have not achieved an analytic sample size large enough to report by non-binary categories in this institutional
                report. While participants with non-binary gender identifications are included in all other analyses, any results
                by gender include faculty identifying as “Women” and “Men”.
            </p>

            <h4>Faculty of color</h4>
            <p>
                For purposes of reporting by race/ethnicity, respondents are grouped into two broad categories: “White, non-Hispanic” and “Faculty of Color” (sometimes labeled “Faculty of Color or Other”), a group including all
                respondents identified by the partner institution or self-identifying in the survey with a race or ethnicity other
                than White, non-Hispanic. In broader analyses than this institutional report provides, COACHE intends to
                more finely and accurately group faculty by their racial-ethnic identity.
            </p>

            <h4>Tenure status</h4>
            <p>
                Some institutions included full-time, non-tenure-track faculty in their survey population. Therefore,
                disaggregation by tenure status at the time of the outside offer includes three groups, “pre-tenure”, “tenured”
                and “non-tenure-track” faculty.
            </p>
            <h4>Academic area</h4>
            <p>
                When the analytic sample size permits, respondent data are clustered into four broad disciplinary categories of
                Humanities, Social Sciences, STEM, and Other Discipline (also labeled “Professions & Other”). These
                aggregations are derived from the “academic area” supplied by partner institutions in their population files or
                imputed by COACHE researchers. We formed these groups as follows:
            </p>
            <div className="grid">
                <div className="grid-row-title-header">Reporting Group</div>
                <div className="grid-row-title-header">Academic Area</div>
            </div>
            <div className="horizontal-line"></div>
            <div className="grid">
                <div className="grid-row-title">Humanities</div>
                <div>
                    <ul className="academic-area-list">
                        <li>Humanities</li>
                        <li>Visual & Performing Arts</li>
                        <li>Interdisciplinary Department/Division - Non-STEM</li>
                    </ul>
                </div>
            </div>
            <div className="grid">
                <div className="grid-row-title">Social Sciences</div>
                <div>Social Sciences</div>
            </div>
            <div className="grid">
                <div className="grid-row-title">STEM</div>
                <div>
                    <ul className="academic-area-list">
                        <li>Biological Sciences</li>
                        <li>Physical Sciences</li>
                        <li>Engineering, Computer Science, Mathematics, Statistics</li>
                        <li>Interdisciplinary Department/Division - STEM</li>
                    </ul>
                </div>
            </div>
            <div className="grid">
                <div className="grid-row-title">Other Disciplines</div>
                <div>
                    <ul className="academic-area-list">
                        <li>Health & Human Ecology</li>
                        <li>Agriculture, Natural Resources, Environmental Science</li>
                        <li>Business</li>
                        <li>Education</li>
                        <li>Medical Schools & Health Professions</li>
                        <li>Other Professions (e.g. Law, Journalism)</li>
                    </ul>
                </div>
            </div>
            
        </>
    )
}

export default Subsection3;