import { FC, useRef, useEffect } from "react";
import * as Plot from "@observablehq/plot";
import { TransformedData } from "../../utils/transformDataUtils";
import { getModifedCategories, Categories } from "../../utils/legendMapping/categoryLegend";
import { groupedBarChartColorScheme } from "../shared/colorScheme";
import { getInstitutionCohortDomain } from "../shared/getInstitutionCohortDomain";
import { defaultXscaleBarChart, plotStyle } from "../shared/observablePlotUtils";

interface DoubleBarChartProps {
    data: TransformedData[];
    selectedFilter: string;
    institutionN: number;
    section?: string
}

const DoubleBarChart: FC<DoubleBarChartProps> = ({
    data,
    selectedFilter,
    institutionN,
    section
}) => {
    const plotContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let chart;
        let width = 900;

        let fillDomain = getInstitutionCohortDomain(institutionN);

        if (plotContainerRef.current && data) {
            plotContainerRef.current.innerHTML = "";
            let modifiedCategories = getModifedCategories("Overall Total Only");

            if (section) {
                if (section === "8.1") {
                    modifiedCategories["exit_status"] = ["Departure"]
                }
                if (section === "9.1") {
                    modifiedCategories["exit_status"] = ["Preemptive Retention"]
                }
            }

            let currentCategories = modifiedCategories[selectedFilter as keyof Categories];

            for (const category of currentCategories) {
                chart = Plot.plot({
                    marks: [
                        Plot.barX(data.filter((item: any) => item.category === category), {
                            x: "value",
                            y: "type",
                            fy: "description",
                            fill: "type",
                            sort: {
                                y: { value: "y", order: null },
                                fy: { value: "y", order: null }
                            },
                            insetTop: 0.2,
                            insetBottom: 0.2,
                            stroke: groupedBarChartColorScheme[0],
                            strokeWidth: .75,
                        }),
                        Plot.text(data.filter((item: any) => item.category === category), {
                            text: d => {
                                if (d.value) {
                                    return `${Math.round(d.value * 10000) / 100}%`
                                }
                            },
                            x: "value",
                            y: "type",
                            fy: "description",
                            dx: 20,
                        }),
                        Plot.axisY({
                            lineWidth: 6,
                        }),
                        Plot.axisFy({
                            anchor: "left",
                            tickPadding: 90,
                            label: null,
                        }),
                        Plot.ruleX([0]),
                    ],
                    x: {
                        ...defaultXscaleBarChart,
                        nice: true
                    },
                    y: { label: null, axis: null },
                    title: category,
                    width: Math.max(width, 550),
                    marginLeft: 400,
                    color: {
                        type: "categorical",
                        legend: true,
                        domain: fillDomain,
                        range: groupedBarChartColorScheme
                    },
                    style: plotStyle,
                });
                plotContainerRef.current.appendChild(chart);
            }
        }
    }, [data, institutionN, section, selectedFilter])

    return (
        <div className="chart" ref={plotContainerRef} />
    )
}

export default DoubleBarChart;