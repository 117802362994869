import { FC } from "react";
import { INSUFFICIENT_N } from "../utils/constants";

interface InstitutionNProps {
    institutionN: number;
}

const InstitutionN: FC<InstitutionNProps> = ({ institutionN }) => {

    return (
        <>
            {institutionN > INSUFFICIENT_N ?
                <p>Your Institution: n = {institutionN}</p>
                : <p>Your Institution: n &lt; 5</p>
            }
        </>
    )
}

export default InstitutionN;