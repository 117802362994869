import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import {
  TransformedData,
  transformDataStackedByRetention
} from "../../utils/transformDataUtils";
import FilterDropdown from "../../components/FilterDropdown";
import StackedByRetention from "../../charts/stackedBarChart/stackedByRetention";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import ApiResponseToCSVConverter from "../../utils/csvDownload/ApiResponseToCsvConverter";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";

const DemographicCharacteristics = () => {
  const [selectedFilter, setSelectedFilter] = useState("exit_status");
  const [apiResponse, setApiResponse] = useState<NestedResponseData | null>(null);
  const [transformedResponse, setTransformedResponse] = useState<TransformedData[] | null>(null);
  const [institutionN, setInstitutionN] = useState(0);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section1/subsection1/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setApiResponse(data);
        setTransformedResponse(transformDataStackedByRetention(data, selectedFilter));
        setInstitutionN(data.institution.total.total);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently, selectedFilter, year]);

  return (
    <>
      <h2>1.1 Demographic characteristics</h2>
      <p>What are the general demographic characteristics of the eligible faculty population?</p>

      {!transformedResponse && <PageLoader />}

      {transformedResponse &&
        <>
          <InstitutionN institutionN={institutionN} />
          <FilterDropdown setSelectedFilter={setSelectedFilter} />
          <StackedByRetention
            data={transformedResponse}
            institutionN={institutionN}
            selectedFilter={selectedFilter}
          />

          <ApiResponseToCSVConverter
            apiResponse={apiResponse}
            selectedFilter={selectedFilter}
            filename="1.1_Demographic_characteristics"
            totalRowOnly={true}
          />
        </>
      }
    </>
  )
}

export default DemographicCharacteristics;