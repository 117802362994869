import "./App.css";
import { useEffect, useState } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import Home from "./pages/Home";
import PageLoader from "./components/PageLoader";
import LoginPage from "./pages/LoginPage";
import QuantitativeReport from "./pages/QuantitativeReport";
import { getProtectedResource } from "./services/apiRequest.service";
import { ApplicableYearsResponse, ApplicableYear } from "./models/responseData";

const determineResponseTypeApplicableYears = (response: any): response is ApplicableYearsResponse => true;

function App() {
    const {
        isLoading,
        isAuthenticated,
        getAccessTokenSilently,
        error
    } = useAuth0();

    const [applicableYearsList, setApplicableYearsList] = useState<ApplicableYear[] | null>(null);

    useEffect(() => {
        const meta = document.createElement("meta");
        meta.httpEquiv = "Content-Security-Policy";
        meta.content = "upgrade-insecure-requests";

        if (window.location.protocol === "https:") {
            document.head.appendChild(meta);
        }
    }, []);

    const getApplicableYears = async () => {
        try {
            const accessToken = await getAccessTokenSilently({ cacheMode: "off" });

            const requestUrl = `admin/applicable_years`;
            const { data } = await getProtectedResource(accessToken, requestUrl);

            if (data && determineResponseTypeApplicableYears(data)) {
                const sortedYears = Object.entries(data)
                    .sort((a: any, b: any) => b[0] - a[0])
                    .map(([year, report_type]) => ({
                        "year": parseInt(year),
                        "report_type": report_type
                    }));
                setApplicableYearsList(sortedYears);
            }
        } catch (error) {
            console.error("Error fetching years:", error);
        }
    }

    if (isLoading) {
        return (
            <div className="page-loader app-page-loader">
                <PageLoader />
            </div>
        );
    }

    if (error) {
        return <div>Oops... {error.message}</div>;
    }

    return (
        <div className="App">
            {isAuthenticated ?
                <Routes>
                    <Route path="*" element={<Navigate to="/" replace />} />
                    <Route index path='/' element={<Home
                        getApplicableYears={getApplicableYears}
                        applicableYearsList={applicableYearsList}
                    />} />
                    <Route path="quantitativeReport/:institution/:reportType/:year/*" element={<QuantitativeReport applicableYearsList={applicableYearsList} />} />
                </Routes>
                :
                <LoginPage />
            }
        </div>
    );
}

export default App;
