

const Introduction = () => {
    return (
        <>
            <h2>Introduction</h2>
            <p>
                We know from the COACHE Faculty Job Satisfaction Study that roughly one in four faculty respondents to
                that survey actively sought employment elsewhere in the preceding five years. Other research (O'Meara, 2015)
                suggests that universities, expecting written proof of a faculty member's marketability, effectively push their
                faculty into searches when an external offer is the only leverage faculty have to renegotiate the terms of their
                employment. COACHE's data confirm that more than three-fourths of tenure-stream university faculty report
                that an outside offer is necessary to renegotiate their existing circumstances. At the institution in O'Meara's
                study (2015), faculty reported that the process of obtaining an external offer weakened their commitment to
                the institution and led them to consider accepting the offer. O'Meara noted that faculty in this position “wanted
                a similar kind of courting they received from the competing side,” and “[w]hen faculty had even a hint they
                would not get this, they moved in the direction of the other offer” (p. 291).
            </p>
            <p>
                The Faculty Retention and Exit Study aims to define the window of opportunity an institution may have to
                intervene (e.g., with a preemptive retention action) before faculty have received an outside offer. The survey
                interrogates the period when faculty were considering leaving and, then, actively searching for a new
                opportunity. The survey counts the number of applications and the number of offers, then asks who initiated
                contact about the outside position. (Pilot study results told us that more faculty who receive outside offers were
                initially contacted by the offering institution, although pre-tenure faculty were more likely than tenured faculty
                to submit a “cold” application.)
            </p>
            <p>
                These survey questions also examine whether renegotiation was the primary motivation for the search, and the
                outcomes of that strategy. Provosts, deans, and chairs can use these data to prompt an examination of why
                faculty believe they need an outside offer to renegotiate. Is it official policy, an unspoken rule, or myth? A
                culture where faculty cannot (or believe they cannot) ask for additional support without another offer, cultivates
                opportunities to test the market—pushing faculty into the market even if they have no desire to be there.
                Furthermore, having a hard offer in hand results in an institution trying to match or beat concrete terms.
                (Lessons from research on loss aversion are pertinent here.) Allowing faculty to ask for new supports before
                they have an outside offer shifts the dynamics of the discussion. Rather than matching an offer line for line,
                faculty and administrators may frame the discussion around a broader set of issues, giving the institution more
                flexibility.
            </p>

            <h3>Questions to Consider</h3>
            <ul>
                <li>
                    What are the indicators that another institution is trying to poach a faculty member from your institution?
                    How might such indicators help administrators deploy preemptive strategies for retention?
                </li>
                <li>
                    What indicators can help administrators determine whether or not to extend a counteroffer to a faculty
                    member who is likely only using an external offer to renegotiate the terms of their employment? How
                    might we help these administrators check their own biases in these determinations?
                </li>
                <li>
                    If “actively searching” candidates who apply for advertised positions are in the minority, then what can we
                    do to better understand the processes by which faculty and potential employers become acquainted with
                    each other? How does this play out?
                </li>
            </ul>
            
        </>
    )
}

export default Introduction;