

const Introduction = () => {
    return (
        <>
            <h2>Introduction</h2>
            <p>
                The period of transition out of one's institution receives little attention in the literature on faculty departures
                and in existing institutional surveys. Some might argue that attention is limited because the faculty member has
                already made the decision. If there is no turning back, then why should an institution extend any extra effort?
            </p>
            <p>
                How faculty are treated in transition matters because disciplines are relatively small communities. The final
                impressions of faculty and treatment by those around them can have long-term and far-reaching effects. Will
                colleagues continue to collaborate with each other? Will former faculty still recommend their advisees to apply
                to graduate programs or faculty positions there? Even when a faculty member leaves under suboptimal
                circumstances, the institution has the opportunity to repair relationships during the transition period.
            </p>
            <p>
                For faculty who accept positions elsewhere, the survey explores their experiences during the transition out of
                their institutions—experiences that only this population of faculty have. The survey asks how departing faculty
                were treated by colleagues, chairs, deans, and administrative staff. It asks how the institution was supportive
                and what it could have done better. Often, discussion of pragmatic issues like equipment transfer and grant
                management arise in the comments we receive. Many faculty in the pilot study mentioned the importance of
                tending to their graduate students, for example, who can become collateral damage in a badly managed
                departure.
            </p>
            <p>
                Institution-level analysis may reveal where—which campuses, which disciplines—deans, colleagues, and chairs
                are making life more difficult for faculty on their way out. Such reports will also identify what the best divisions
                and departments do to leave faculty feeling positive about their institutions.
            </p>

            <h3>Questions to Consider</h3>
            <ul>
                <li>
                    What existing structures do grants administration offices have in place to assist with the closing or
                    transference of grants when faculty leave the institution?
                </li>
                <li>
                    How are graduate students supported when their advisors/mentors leave the institution?
                </li>
                <li>
                    What interventions in policy or practice can help change chairs', deans', and faculty colleagues' behaviors
                    toward faculty on the way out?
                </li>
            </ul>
            
        </>
    )
}

export default Introduction;