import { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { convertToPercentString, determineNdisplay, formatLabels } from "../../utils/utils";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import TableToCSVConverter from "../../utils/csvDownload/TableToCsvConverter";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";

const positionsTableA = {
  "assistant_pre_tenure": "Assistant, pre-tenure",
  "associate_pre_tenure": "Associate, pre-tenure",
  "associate_tenured": "Associate, tenured",
  "full_professor_tenured": "Full Professor, tenured",
  "endowed_chair_professor": "Endowed Chair Professor"
}

const positionsTableB = {
  ...positionsTableA,
  "other": "Other Academic Rank"
}


const Subsection6oldTable = () => {
  const [responseA, setResponseA] = useState<NestedResponseData | null>(null);
  const [responseB, setResponseB] = useState<NestedResponseData | null>(null);
  const [institutionNresponseA, setInstitutionNresponseA] = useState(0);
  const [institutionNresponseB, setInstitutionNresponseB] = useState(0);
  const tableRefA = useRef<HTMLTableElement>(null);
  const tableRefB = useRef<HTMLTableElement>(null);
  const [tableA, setTableA] = useState<HTMLTableElement | null>(null);
  const [tableB, setTableB] = useState<HTMLTableElement | null>(null);
  const [loadingA, setLoadingA] = useState(true);
  const [loadingB, setLoadingB] = useState(true);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getDataA = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection2a/?report_year=${year}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setResponseA(data);
        setInstitutionNresponseA(data.institution.total.total);
        setLoadingA(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataB = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection2b/?report_year=${year}&display_original_data=True`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setResponseB(data);
        setInstitutionNresponseB(data.institution.total.total);
        setLoadingB(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataA();
    getDataB();
  }, [getAccessTokenSilently, year]);

  useEffect(() => {
    if (tableRefA.current) {
      setTableA(tableRefA.current);
    }
  }, [responseA]);

  useEffect(() => {
    if (tableRefB.current) {
      setTableB(tableRefB.current);
    }
  }, [responseB]);


  return (
    <>
      <h2>Rank, tenure and title: Pre-offer vs. Offer</h2>
      <p>
        What academic rank/tenure did you have at your institution at the time you received an outside offer? <br />
        What academic rank/tenure was offered to you in the outside offer you received? <br />
        Which of the following administrative titles, if any, were included in the outside offer?
      </p>

      <div className="page-layout">
        <div>
          <h4>
            Comparison of rank/tenure status at your institution vs. in the outside offer
          </h4>

          {!responseA && <PageLoader />}
          {responseA &&
            <>
              <InstitutionN institutionN={institutionNresponseA} />
              <table ref={tableRefA} className="preoffer-offer-table">
                <thead>
                  <tr>
                    <th colSpan={2}></th>
                    <th colSpan={Object.keys(positionsTableA).length * 2} className="offer-type-title ">At Time of Offer</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th className="row-split"></th>
                    {Object.values(positionsTableA).map((position, index) => (
                      <Fragment key={index}>
                        <th className="institution-cohort-header row-split" colSpan={2}>
                          {position}
                        </th>
                      </Fragment>
                    ))}
                  </tr>
                  <tr className="column-headers">
                    <th colSpan={2} rowSpan={2} className="row-split offer-type-title">
                      In Outside Offer
                    </th>
                    {Object.keys(positionsTableA).map((_, index) => (
                      <Fragment key={index}>
                        <th>#</th>
                        <th className="row-split">%</th>
                      </Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(responseA).map((population, i) =>
                    <Fragment key={i}>
                      <tr>
                        <th rowSpan={Object.keys(positionsTableA).length + 1} className="row-split-type row-header no-alt-color">{formatLabels(population)}</th>

                        <th className="row-header">Assistant, pre-tenure</th>
                        {Object.keys(positionsTableA).map((position, i) => {
                          let current = responseA[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.assistant_pre_tenure)}</td>
                              <td className="row-split">{convertToPercentString(current.assistant_pre_tenure, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Associate, pre-tenure</th>
                        {Object.keys(positionsTableA).map((position, i) => {
                          let current = responseA[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.associate_pre_tenure)}</td>
                              <td className="row-split">{convertToPercentString(current.associate_pre_tenure, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Associate, tenured</th>
                        {Object.keys(positionsTableA).map((position, i) => {
                          let current = responseA[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.associate_tenured)}</td>
                              <td className="row-split">{convertToPercentString(current.associate_tenured, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Full Professor, tenured</th>
                        {Object.keys(positionsTableA).map((position, i) => {
                          let current = responseA[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.full_professor_tenured)}</td>
                              <td className="row-split">{convertToPercentString(current.full_professor_tenured, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Endowed Chair Professor</th>
                        {Object.keys(positionsTableA).map((position, i) => {
                          let current = responseA[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.endowed_chair_professor)}</td>
                              <td className="row-split">{convertToPercentString(current.endowed_chair_professor, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>

                      <tr className="last-row-split">
                        <th className="row-header">Total</th>
                        {Object.keys(positionsTableA).map((position, i) => {
                          let current = responseA[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.total, true, true)}</td>
                              <td className="row-split">{convertToPercentString(current.total, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
              <TableToCSVConverter
                table={tableA}
                filename="Appendix_10a_Rank,_tenure_and_title"
                loading={loadingA}
                setLoading={setLoadingA}
                section="6.2"
              />
            </>
          }
        </div>

        <div>
          <h4>
            Administrative appointments included in the outside offers, by faculty rank/tenure status at the time of offer
          </h4>

          {!responseB && <PageLoader />}
          {responseB &&
            <>
              <InstitutionN institutionN={institutionNresponseB} />
              <table ref={tableRefB} className="preoffer-offer-table">
                <thead>
                  <tr>
                    <th colSpan={2}></th>
                    <th colSpan={Object.keys(positionsTableB).length * 2} className="offer-type-title ">At Time of Offer</th>
                  </tr>
                  <tr>
                    <th></th>
                    <th className="row-split"></th>
                    {Object.values(positionsTableB).map((position, index) => (
                      <Fragment key={index}>
                        <th className="institution-cohort-header row-split" colSpan={2}>
                          {position}
                        </th>
                      </Fragment>
                    ))}
                  </tr>
                  <tr className="column-headers">
                    <th colSpan={2} rowSpan={2} className="row-split offer-type-title">In Outside Offer</th>
                    {Object.keys(positionsTableB).map((_, index) => (
                      <Fragment key={index}>
                        <th>#</th>
                        <th className="row-split">%</th>
                      </Fragment>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(responseB).map((population, i) =>
                    <Fragment key={i}>
                      <tr>
                        <th rowSpan={Object.keys(positionsTableB).length} className="row-split-type row-header no-alt-color">{formatLabels(population)}</th>

                        <th className="row-header">I was not offered an administrative title</th>
                        {Object.keys(positionsTableB).map((position, i) => {
                          let current = responseB[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.no_administrative_title)}</td>
                              <td className="row-split">{convertToPercentString(current.no_administrative_title, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Department Chair/Head, Associate or Assistant Chair/Head</th>

                        {Object.keys(positionsTableB).map((position, i) => {
                          let current = responseB[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.chair_or_head)}</td>
                              <td className="row-split">{convertToPercentString(current.chair_or_head, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Center or Program Director</th>
                        {Object.keys(positionsTableB).map((position, i) => {
                          let current = responseB[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.director)}</td>
                              <td className="row-split">{convertToPercentString(current.director, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>
                      <tr>
                        <th className="row-header">Dean, Associate Dean, Assistant Dean, Vice Dean, Division Chief</th>

                        {Object.keys(positionsTableB).map((position, i) => {
                          let current = responseB[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.dean)}</td>
                              <td className="row-split">{convertToPercentString(current.dean, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>

                      <tr>
                        <th className="row-header">Other Administrative Title</th>

                        {Object.keys(positionsTableB).map((position, i) => {
                          let current = responseB[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.other)}</td>
                              <td className="row-split">{convertToPercentString(current.other, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>
                      <tr className="last-row-split">
                        <th className="row-header">Total</th>

                        {Object.keys(positionsTableB).map((position, i) => {
                          let current = responseB[population][position];
                          return (
                            <Fragment key={i}>
                              <td>{determineNdisplay(current.total, true)}</td>
                              <td className="row-split">{convertToPercentString(current.total, current.total)}</td>
                            </Fragment>
                          )
                        })}
                      </tr>
                    </Fragment>
                  )}
                </tbody>
              </table>
              <TableToCSVConverter
                table={tableB}
                filename="Appendix_10b_Rank,_tenure_and_title"
                loading={loadingB}
                setLoading={setLoadingB}
                section="6.2"
              />
            </>
          }
        </div>
      </div>
    </>
  )
}

export default Subsection6oldTable;