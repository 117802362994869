import { useEffect } from "react";
import BottomNavigation from "../navigation/BottomNavigation";
import BreadcrumbNav from "../navigation/Breadcrumb";

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <BreadcrumbNav section="AboutThisReport" onAboutPage={true} />

      <h1>About This Report</h1>

      <h2>The Collaborative on Academic Careers in Higher Education (COACHE)</h2>
      <p>
        COACHE, a research-practice partnership at the Harvard Graduate School of Education, is dedicated to
        gaining insight into the postsecondary faculty experience. Academic leaders at more than 250 colleges,
        universities, community colleges and systems are partnering with COACHE and using our data to improve the
        quality of work life and professional fulfillment for faculty. They are also using our data to improve the
        recruitment, development, promotion and retention of a talented and diverse professoriate.
      </p>

      <h2>The COACHE Faculty Retention & Exit Study</h2>
      <p>
        A tenure line faculty appointment is one of the few career paths left where an individual can expect to remain
        secure with the same institution for the duration of his or her career. Therefore, when faculty explore the
        possibility of leaving, it raises questions about what might have gone wrong.
      </p>
      <p>
        The COACHE Faculty Retention and Exit Study is working with university partners to standardize the data
        collected and stored about faculty who receive outside offers, in order to identify patterns in the <em>causes</em>, <em>costs</em>,
        and <em>conduct</em> of faculty mobility. More than 30 institutions have joined COACHE in this effort.
      </p>
      <p>
        The primary tool of this study is an online survey instrument with themes that cover the search for a new
        position; the nature of the outside offer; the factors that weigh into a decision to stay or leave; the influence of
        spouses' and partners' careers; the counteroffer process; the transition to a new institution; and of course, the
        demographics of our population.
      </p>
      <p>
        This report provides you with the opportunity to understand your institution's relative strengths and
        opportunities for improvement in the retention of faculty compared with other research universities across the
        country. We hope that this report will help shine a light on the challenges your faculty face so that we can make
        the academy a more welcoming and equitable place to work.
      </p>

      <h2>Structure of this report</h2>
      <div>
        COACHE has completed aggregation of all data from this study's survey administrations in the academic years
        2019-20, 2020-21, and 2021-22. Upon the review and advice of our advisory group, these results are being
        shared in two parts:
      </div>
      <ol type="a">
        <li>
          The <em>Text Response Report</em> contains qualitative data for your institution alone. Although comments in that
          report are redacted, they are candid and illuminating. As indicated in that report's preface, the report of
          text responses requires your careful review and additional treatment before you share its contents with
          anyone else.
        </li>
        <li>
          This <em>Quantitative Report</em> contains visualizations and data tables for your institution with comparisons to the
          entire cohort of over 30 research universities participating in the study. While it still merits a careful review
          to avoid any deductive disclosure of respondent identities, this report is intended for broader dissemination.
          Each report completes the other.
        </li>
      </ol>
      <div>
        Each report completes the other. You may note that the numbering in the <em>Table of Contents</em> appears to skip items
        in its sequence. This is to emphasize that important questions are answered in the companion report.
      </div>

      <h2>How might these data be used?</h2>
      <div>
        Many examples of the utility of exit data emerged in the earlier phases of our research. Some benefits apply to
        a system or consortium of universities, while others are realized by individual campuses. Knowledge gained
        from analysis of faculty departures and retentions in this report could help by:
      </div>
      <ul>
        <li>
          Suggesting improvements to department chair training in anticipating faculty intent to leave and in handling
          negotiations;
        </li>
        <li>
          Identifying more quickly - than could a single institution - any resignation patterns with respect to
          disciplinary cultures, gender, or underrepresented status;
        </li>
        <li>
          Finding out if competitors in the faculty labor market are offering particular inducements that make a
          difference in successfully “poaching” faculty;
        </li>
        <li>
          Educating deans about the efficacy of “home field advantage” in pre-emptive retention actions and
          counteroffers;
        </li>
        <li>
          Giving budget officers the basis for projections about where new hiring opportunities should be made
          available;
        </li>
        <li>
          Providing fundable propositions for interactions with foundations (e.g., NSF ADVANCE);
        </li>
        <li>
          Creating compelling cases to donors in the name of retaining the best and brightest talent, for example, by
          endowing chairs, funding a lab school for children of faculty, allowing more teaching on recall, or
          subsidizing faculty housing;
        </li>
        <li>
          Revealing whether or not universities are effectively carrying out their missions; and
        </li>
        <li>
          Offering sound research—colored with poignant anecdotes—in support of appropriations requests to the
          state legislature.
        </li>
      </ul>

      <h2>Origin of this COACHE study</h2>
      <p>
        In 2013, COACHE launched an exploratory study of faculty turnover as a “proof of concept” to gauge the
        merit of a sustained inquiry. That study—a literature review and an analysis of institutional exit surveys—
        concluded that the scholarship of faculty mobility and the exit interview and survey practices in place were not
        partnered in an effective development and management of faculty. Despite half a century of research on the
        subject, most universities had not yet routinized data collection about departures, nor had they developed
        systems for identifying, negotiating with, retaining or supporting the transition of faculty who were planning to
        leave.
      </p>
      <p>
        The gap between research and practice presented COACHE with an opportunity to help university leaders
        make significant improvements in their approach to faculty retention. With an investment supplemented by
        financial and advisory assistance from the University of California (UC) Office of the President, COACHE
        launched a pilot study—the Academic Workforce Mobility Project—in 2015. The first multi-institutional
        Faculty Retention & Exit Survey resulted from deeper consultation of the literature, engagement of scholars
        on the professoriate, and oversight from an advisory board of academic leaders across the UC System
      </p>
      <p>
        The data aggregated in this report were collected after survey administrations in Fall 2019, Fall 2020, and Fall
        2021.
      </p>
      <h2>Goals of the study</h2>
      <p>
        During the pilot study, our interviews, meetings and roundtables with academic leaders revealed local, state,
        and national contexts for their interest in gathering faculty departure and retention data. Most described the
        importance of these data in terms of the costs of a single faculty member's departure (see Kaminski & Geisler,
        2012), often expressed in terms of (a) investment in the search, hiring, and development of the faculty member;
        and (b) the contributions that person makes to the institution, now no longer to be realized.
      </p>
      <p>
        In addition to these concerns is the low morale departments suffer when they lose faculty stars and good
        colleagues, not to mention the loss of national reputation to academic programs, which might subsequently be
        ranked lower as a result of the loss.
      </p>
      <p>
        Most importantly, beyond lost investment and contributions, we at COACHE describe the problem of
        departures as a matter of equity. Do some groups leave for reasons different than—or even as a result of—
        other groups? Might knowing the answer help us address their concerns?
      </p>
      <p>
        We asked academic administrators to articulate the outcomes that would lead them to agree, upon this report
        delivery, that this effort was worthwhile. Their replies are summarized into five goals below, each increasing by
        degree in the time and resources it will be required to achieve them.
      </p>
      <ol type="a">
        <li>An improved, validated survey instrument to replace current or past protocols</li>
        <li>An accurate understanding of the reasons why faculty choose to leave</li>
        <li>An accurate understanding of the reasons why faculty who receive outside offers choose to stay</li>
        <li>A clearer understanding of how to succeed at retention actions</li>
        <li>A clearer understanding of how to prevent retention cases in the first place</li>
      </ol>
      <p>
        Therefore, an ambitious (but not unattainable) outcome of a sustained and pervasive commitment to this work
        could be a positive impact on the rate of departures and/or fewer retention requests, while identifying and
        eliminating any troubling inequities in the course of retention and departure routines.
      </p>

      <h2>A final reminder of the unique value of an exit survey</h2>
      <p>
        There is, in fact, much that can be accomplished by a study of faculty who have left that cannot be learned
        from a survey of faculty who are still in the process of deciding
      </p>
      <ul>
        <li>
          Faculty who <em>intend</em> to leave report they would leave for more prestigious departments and better pay, but
          those who <em>actually</em> leave report more diverse factors, especially work environment (O'Meara, 2014).
        </li>
        <li>
          Direct accounts from faculty who have left reveal that reasons for departure are more quickly developed
          and less resource-driven than most in the academic community assume, or that the most retention activity
          may not be in the sciences, but in other disciplines, because “you need to find a lot more money to move
          a scientist.”
        </li>
        <li>
          In our studies of other universities' exit surveys, interviewees described the “the intrinsic value in engaging
          people as they leave.” The word “respect” was often uttered; faculty want to be heard.
        </li>
        <li>
          Only from those who have left can we learn how, and how effectively, an institution receives and responds
          to an individual's expression of an intent to leave. Are chairs, deans, and colleagues responding
          appropriately? What damage is done or care taken by these agents throughout the stages of separation?
          Ultimately, what can we learn from those who leave their faculty feeling <em>better</em> about their former employer?
        </li>
      </ul>
      <BottomNavigation />
    </>
  )
}

export default About;