import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { TripleNestedResponseData } from "../../models/responseData";
import {
  TransformedData,
  transformTripleNestedDataStackedByRetention,
} from "../../utils/transformDataUtils";
import FilterDropdown from "../../components/FilterDropdown";
import DoubleBarChartNested from "../../charts/doubleBarChart/DoubleBarChartNested";
import InstitutionN from "../../components/InstitutionN";
import PageLoader from "../../components/PageLoader";
import ApiResponseToCSVConverter from "../../utils/csvDownload/ApiResponseToCsvConverter";
import { determineResponseTypeTripleNestedResponseData } from "../../utils/determineResponseType";


const SubSection6 = () => {
  const [selectedFilter, setSelectedFilter] = useState("exit_status");
  const [apiResponse, setApiResponse] = useState<TripleNestedResponseData | null>(null);
  const [transformedResponse, setTransformedResponse] = useState<TransformedData[] | null>(null);
  const [validCategoriesForChart, setValidCategoriesForChart] = useState<any>()
  const [institutionN, setInstitutionN] = useState(0);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection6/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTripleNestedResponseData(data)) {
        setApiResponse(data);
        let transformedResponse = transformTripleNestedDataStackedByRetention(data, selectedFilter, "6.6");
        setTransformedResponse(transformedResponse[0]);
        setValidCategoriesForChart(transformedResponse[1]);
        setInstitutionN(data.institution.total.total as unknown as number);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();

  }, [getAccessTokenSilently, selectedFilter, year]);

  return (
    <>
      <h2>6.6 Your counteroffer: Proposed changes</h2>
      <p>
        Please mark all of the proposed changes included in the counteroffer from your institution.
      </p>
      <p>
        Note: This question is only for individuals who received a counteroffer.
      </p>

      {!transformedResponse && <PageLoader />}

      {transformedResponse &&
        <>
          <InstitutionN institutionN={institutionN} />
          <FilterDropdown setSelectedFilter={setSelectedFilter} />
          <DoubleBarChartNested
            data={transformedResponse}
            selectedFilter={selectedFilter}
            validCategoriesForChart={validCategoriesForChart}
          />
          <ApiResponseToCSVConverter
            apiResponse={apiResponse}
            selectedFilter={selectedFilter}
            filename="6.6_Your_counteroffer_Proposed_changes"
            section="6.6"
          />
        </>
      }

      
    </>
  )
}

export default SubSection6;