interface LegendInterface {
    [key: string]: {
        [choice: string]: string;
    }
}

export const answerChoiceLegendDomain: LegendInterface = {
    "3.1": {
        "married": "Married/Partnered",
        "single": "Single, divorced, separated, or widowed",
        "decline_to_answer": "Decline to answer"
    },
    "3.2": {
        "unrelated": "Unrelated to my search for a new position",
        "myself_first": "Received an offer first, then my partner/spouse secured or searched for a job to move with me",
        "partner_first": "Partner/spouse secured a job elsewhere first, then secured or searched for a new position",
        "dual_hire": "Partner/spouse and I received simultaneous offers(i.e. 'dual-hire offers')",
        "other": "Other partner/spouse circumstances",
        "decline_to_answer": "Decline to answer",
    },
    "3.3": {
        "same_institution": "Employed at same institution as you",
        "different_institution_or_self_employed": "Employed elsewhere/Self-employed",
        "enrolled_as_student": "Enrolled as student",
        "not_employed_not_seeking_employment_or_retired": "Not employed and not seeking employment/Retired",
        "not_employed_seeking_employment": "Not employed and seeking employment",
        "other": "Other",
        "decline_to_answer": "Decline to Answer",
    },
    "3.4": {
        "faculty_member": "A full or part-time faculty member within an academic institution",
        "administrator": "An administrator or staff member within an academic institution",
        "postdoc": "A research appt or postdoc within an academic institution",
        "student": "A student within an academic institution",
        "role_outside_academic_institution": "A role outside of higher education",
        "other": "Other",
        "two_or_more": "Two or more of the above",
        "decline_to_answer": "Decline to Answer",
    },
    "4.1a": {
        "never_considered_leaving": "I never considered leaving",
        "less_than_six_months": "Less than 6 months",
        "six_to_twelve_months": "6-12 months",
        "one_to_two_years": "1-2 years",
        "more_than_two_years": "More than 2 years",
        "decline_to_answer": "Decline to answer",
    },
    "4.1b": {
        "never_actively_searched": "I never actively searched",
        "less_than_six_months": "Less than 6 months",
        "six_to_twelve_months": "6-12 months",
        "one_to_two_years": "1-2 years",
        "more_than_two_years": "More than 2 years",
        "decline_to_answer": "Decline to answer",
    },
    "4.2": {
        "use_offer_as_leverage": "  To use an offer as leverage to renegotiate the terms of my employment    ",
        "to_leave": "To leave",
        "did_not_initiate_search": " I did not initiate a search for a job offer",
        "other": "Other motivation for initiating a search",
        "decline_to_answer": "Decline to answer",
    },
    "4.3a": {
        "0": "0",
        "1-2": "1-2",
        "3-4": "3-4",
        "5-6": "5-6",
        "7+": "7+",
        "decline_to_answer": "Decline to answer",
    },
    "4.3b": {
        "1": "1",
        "2": "2",
        "3+": "3+",
        "decline_to_answer": "Decline to answer",
    },
    "4.4": {
        "contacted_unadvertised_position": "Was contacted to submit an application to an unadvertised position",
        "contacted_advertised_position": "Was contacted to submit an application to an advertised position",
        "unsolicited_application_advertised_position": "Submitted an application to an advertised job position ",
        "contacted_institution_unadvertised_position": "Contacted the institution about an unadvertised position",
        "contacted_potential_offer": "Was contacted to ask if I would be receptive to a potential offer",
        "contacted_immediate_offer": "Was contacted and immediately presented a verbal/formal offer",
        "other": "Other",
        "decline_to_answer": "Decline to answer",
    },
    "5.1": {
        "informed_chair_or_head": "I informed my chair or head about the offer",
        "informed_dean": "I informed my dean about the offer",
        "informed_colleague": "I informed a colleague, and they informed the chair/head",
        "did_not_inform_anyone": "I accepted the offer before speaking with anyone",
        "other": "Other means of notification",
        "decline_to_answer": "Decline to answer",
    },
    "5.2a": {
        "not_at_all_seriously": "Not at all seriously",
        "slightly_seriously": "Slightly seriously",
        "moderately_seriously": "Moderately seriously",
        "quite_seriously": "Quite seriously",
        "extremely_seriously": "Extemely seriously",
        "decline_to_answer": "Decline to answer",
    },
    "5.2b": {
        "not_at_all_seriously": "Not at all seriously",
        "slightly_seriously": "Slightly seriously",
        "moderately_seriously": "Moderately seriously",
        "quite_seriously": "Quite seriously",
        "extremely_seriously": "Extemely seriously",
        "decline_to_answer": "Decline to answer",
    },
    "6.5": {
        "did_seek_counteroffer_given_counteroffer": "I sought a counteroffer; a counteroffer was made",
        "did_seek_counteroffer_no_counteroffer": "I sought a counteroffer; none was made",
        "did_not_seek_counteroffer_given_counteroffer": "I did not seek a counteroffer; a counteroffer was made",
        "did_not_seek_counteroffer_no_counteroffer": "I did not seek a counteroffer; none was made",
        "did_not_seek_counteroffer_made_on_interest": "I did not seek a counteroffer; had I expressed interest, one would have been made",
        "other": "Other circumstances",
        "decline_to_answer": "Decline to answer",
    },
    "6.6": {
        "base_salary": "Base salary",
        "other_compensation": "Other compensation (e.g., summer salary)",
        "job_description_or_responsibilities": "Job description or responsibilities",
        "academic_rank_or_series": "Academic rank or series",
        "research_space": "Research space",
        "research_support": "Research support (e.g., equipment, infrastructure)",
        "graduate_student_or_postdoc_support": "Graduate student or postdoc support",
        "teaching_responsiblities": "Teaching responsibilties",
        "work_hours": "Work hours",
        "proportion_of_salary_from_institutional_funding": "Proportion of salary from institutional funding",
        "change_in_supervisors": "Change in supervisors",
        "assistance_with_personal_or_family_issues": "Assistance with personal/family issues",
        "employment_offer_for_spouse_or_partner": "An employment offer for your spouse/partner",
        "assistance_with_spouse_or_partner_employment_search": "Assistance with spouse/partner employment search",
        "other": "Other changes",
        "decline_to_answer": "Decline to answer",
    },
    "6.7": {
        "exceeded_the_outside_offer": "Exceeded the outside offer",
        "completely": "Completely",
        "mostly": "Mostly",
        "somewhat": "Somewhat",
        "not_at_all": "Not at all",
        "decline_to_answer": "Decline to answer",
    },

    "7.1": {
        "much_worse": "Much worse",
        "somewhat_worse": "Somewhat worse",
        "about_the_same": "About the same",
        "somewhat_better": "Somewhat better",
        "much_better": "Much better",
        "decline_to_answer": "Decline to answer",
        "not_applicable": "Not applicable",
    },

    "8.1": {
        "higher_salary": "Higher base/supplemental salary",
        "lower_teaching_load": "Lower teaching load",
        "tenure_timeline": "Tenure timeline",
        "promotion_timeline": "Promotion timeline",
        "additional_leadership_opportunities": "Additional leadership opportunities",
        "research_or_lab_support": "Research/lab support",
        "assistance_finding_employment_for_partner": "Assistance in finding employment for spouse/partner",
        "sabbatical_or_other_leave_time": "Sabbatical or other leave time",
        "department_climate": "Climate of the department",
        "leadership_changes": "Changes to departmental or divisional leadership",
        "recognition_of_performance": "More recognition for my performance",
        "could_not_convince_to_stay": "I could not have been convinced to remain at INSTITUTION",
        "other": "Other change",
        "decline_to_answer": "Decline to answer"
    },
    "8.2": {
        "very_satisfied": "Very satisfied",
        "satisfied": "Satisfied",
        "neither_satisfied_or_dissatisfied": "Neither satisfied nor dissatisfied",
        "dissatisfied": "Dissatisfied",
        "very_dissatisfied": "Very dissatisfied",
        "decline_to_answer": "Decline to answer",
    },
    "8.3": {
        "strongly_recommend": "Strongly recommend your department as a place to work",
        "recommend_with_reservations": "Recommend your department with reservations",
        "not_recommend": "Not recommend your department as a place to work",
        "decline_to_answer": "Decline to answer",
    },
    "8.4": {
        "very_unlikely": "Very unlikely",
        "somewhat_unlikely": "Somewhat unlikely",
        "somewhat_likely": "Somewhat likely",
        "very_likely": "Very likely",
        "dont_know": "Don't know",
        "decline_to_answer": "Decline to answer",
    },
    "9.1": {
        "base_salary": "Base salary",
        "academic_rank_or_series": "Academic rank or series",
        "research_support": "Research support (e.g., equipment, infrastructure)",
        "research_space": "Research space",
        "teaching_responsiblities": "Teaching responsibilties",
        "job_description_or_responsibilities": "Job description or responsibilities",
        "change_in_supervisors": "Change in supervisors",
        "graduate_student_or_postdoc_support": "Graduate student or postdoc support",
        "other_compensation": "Other compensation (e.g., summer salary)",
        "proportion_of_salary_from_institutional_funding": "Proportion of salary from institutional funding",
        "employment_offer_for_spouse_or_partner": "An employment offer for your spouse/partner",
        "assistance_with_personal_or_family_issues": "Assistance with personal/family issues",
        "assistance_with_spouse_or_partner_employment_search": "Assistance with spouse/partner employment search",
        "work_hours": "Work hours",
        "other": "Other changes",
        "decline_to_answer": "Decline to answer",
    }
}