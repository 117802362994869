import { useState, useEffect, useRef, Fragment } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import { determineNdisplay, roundNumber } from "../../utils/utils";
import TableToCSVConverter from "../../utils/csvDownload/TableToCsvConverter";
import PageLoader from "../../components/PageLoader";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";
import { retentionCategoriesWithOverallMap } from "../../utils/legendMapping/categoryLegend";

const InstitutionalPersistence = () => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [response, setResponse] = useState<NestedResponseData | null>(null);
  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState<HTMLTableElement | null>(null);

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section1/subsection2/?report_year=${year}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setResponse(data);
        setLoading(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently, year]);

  useEffect(() => {
    if (tableRef.current) {
      setTable(tableRef.current);
    }
  }, [response]);

  return (
    <>
      <h2>1.2 Institutional persistence</h2>
      <p>
        How many years had eligible faculty spent at your institution at the point of retention or departure?
        (<em>Available only to institutions who provided <span className="red-variable-name">yearhire</span> to COACHE.</em>)
      </p>

      {!response && <PageLoader />}

      {response &&
        <>
          <table ref={tableRef}>
            <thead>
              <tr>
                <th></th>
                <th colSpan={6}>Your Institution</th>
                <th colSpan={6}>Cohort</th>
              </tr>
              <tr className="column-headers">
                <th className="row-header"></th>
                <th>Valid n</th>
                <th>Mean</th>
                <th>Median</th>
                <th>Min</th>
                <th>Max</th>
                <th className="row-split">SD</th>
                <th>Valid n</th>
                <th>Mean</th>
                <th>Median</th>
                <th>Min</th>
                <th>Max</th>
                <th>SD</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(retentionCategoriesWithOverallMap).map((retentionStatus, i) => {
                let rowClassName = "";
                if (retentionStatus === "overall") {
                  rowClassName = "grand-total-row"
                }
                return (
                  <tr className={rowClassName} key={i}>
                    <th className="row-header">{retentionCategoriesWithOverallMap[retentionStatus]}</th>
                    {["institution", "cohort"].map((type: string, i: number) => {
                      let current = response[type][retentionStatus];
                      return <Fragment key={i}>
                        <td>{current && current.count ? determineNdisplay(current.count, true, true, false) : "n < 5"}</td>
                        <td>{current && current.count ? roundNumber(current.mean, false, true) : "n < 5"}</td>
                        <td>{current && current.count ? roundNumber(current.median, false, true) : "n < 5"}</td>
                        <td>{current && current.count ? roundNumber(current.min, false, true) : "n < 5"}</td>
                        <td>{current && current.count ? roundNumber(current.max, false, true) : "n < 5"}</td>
                        <td className={`${type === "institution" && "row-split"}`}>{current && current.count ? roundNumber(current.stdev, true, true) : "n < 5"}</td>
                      </Fragment>
                    })}
                  </tr>)
              }
              )}
            </tbody>
          </table>
          <TableToCSVConverter
            table={table}
            filename="1.2_Institutional_persistence"
            loading={loading}
            setLoading={setLoading}
          />
        </>
      }
    </>
  )
}

export default InstitutionalPersistence;