export const fourSequential = [
    "#05243B",
    "#537d9f",
    "#82aac1",
    "#b7dbe8"
]

export const fiveSequential = [
    "#05243B",
    "#335d80",
    "#537d9f",
    "#769eb9",
    "#9ac1d0"
]

export const sixSequential = [
    "#05243B",
    "#2b5374",
    "#537d9f",
    "#6a93b1",
    "#9ac1d0",
    "#b7dbe8"
]

export const sevenSequential = [
    "#05243B",
    "#2b5374",
    "#537d9f",
    "#6a93b1",
    "#82aac1",
    "#9ac1d0",
    "#b7dbe8"
]

export const stackedBarChartColorScheme = [
    "#4978AA",
    "#F28E2C",
    "#EE4D59",
    "#76BAAF",
    "#DD8FA9",
    "#EBCA3F",
    "#5F9F51",
    "#DE6328"
]

export const groupedBarChartColorScheme = [
    "#185C90",
    "#A5BBC3"
]

export const COLORS_NEEDING_WHITE_TEXT = [
    "#05243B", 
    "#223f55", 
    "#2b5374", 
    "#3d678c", 
    "#335d80", 
    "#185C90", 
    "#EA8F02", 
    "#4978AA"
]