export const convertTableToCSV = (table: HTMLTableElement | null, section?: string): string => {
    if (!table) {
        return "";
    }

    // Function to remove special characters
    const removeSpecialChars = (text: string): string => {
        return text.replace(/&shy;|,/g, ""); // Remove soft hyphen and comma
    };

    const headerRows: string[][] = [[]]; // Initialize with an empty array for the first row
    const dataRows: string[][] = [];

    // Extract header cells from thead elements
    Array.from(table.querySelectorAll("thead tr")).forEach((row, rowIndex) => {

        const rowData: string[] = [];
        Array.from(row.querySelectorAll("th, td")).forEach(cell => {
            const cellContent = removeSpecialChars(cell.textContent?.trim() || "");
            const colSpan = parseInt(cell.getAttribute("colSpan") || "1", 10);

            // Push cellContent to rowData with commas for colSpan
            if (colSpan > 1) {
                rowData.push(cellContent);
                rowData.push(...Array(colSpan - 1).fill("")); // Fill the rest with empty CSV values
            } else {
                rowData.push(cellContent);
            }
        });
        headerRows[rowIndex] = rowData; // Save header row
    });


    Array.from(table.querySelectorAll("tbody tr")).forEach((row, rowIndex) => {
        const rowData: string[] = [];

        Array.from(row.querySelectorAll("th, td")).forEach(cell => {
            const cellContent = removeSpecialChars(cell.textContent?.trim() || "");

            rowData.push(cellContent);
        });

        dataRows.push(rowData);
    });


    // Combine multiline headers
    const headerCSV = headerRows.map(row => row.join(",")).join("\n");

    // Combine data rows
    let dataCSV = dataRows.map(row => row.join(",")).join("\n");


    // handle edge case for 6.2 with row span
    if (section === "6.2") {
        dataRows.forEach((row) => {
            if (row[0] !== "," && (row[0] !== "Your Institution" && row[0] !== "Cohort")) {
                row.unshift("");
            }
        })
        dataCSV = dataRows.join("\n");
    }

    return `${headerCSV}\n${dataCSV}`;
};