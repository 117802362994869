

const Introduction = () => {
    return (
        <>
            <h2>Introduction</h2>
            <p>
                O'Meara and her co-authors (2014) describe the habit of college administrators to describe faculty departures
                in terms of “heaven” (they went to a better place than here) or “hell” (they weren't good enough for here).
                Gathering systematic information about the direction of the move (or potential move) extends O'Meara's study
                by revealing in greater detail whether the campus community's sensemaking is accurate. The administrative and
                respondent data we collect about the origins of the offer provide institutions with a clearer picture of their place
                in the faculty labor market, including specific information about their greatest threats. This knowledge can help
                institution prepare thoughtful questions or data points for deans, chairs, and faculty about the comparative
                strengths of their workplace.
            </p>
            <p>
                The literature on the professoriate and our observations of universities' own exit surveys suggest that while
                higher salary and refreshed start-up packages are appealing components of outside offers, other factors are
                often at play; matching or exceeding compensation may not be a sufficient or necessary response to stave off
                a departure. This COACHE study looks below the letterhead at components of the outside offer (including
                rank, tenure status, and title), at the value and permissible uses of any startup package, and at how the monetary
                value of the offers compare to their compensation and resources at home.
            </p>
            <p>
                Learning from literature about differences in who negotiates and about who is rewarded and who is punished
                for doing so, we also delve into whether or not respondents seek a counteroffer, what the counteroffer includes
                if they receive one, and the extent to which changes proposed in a counteroffer (if sought and if received)
                match up. These results may help us understand how counteroffers differ between those who stay (i.e., for
                whom the counteroffer was effective) and those who leave (i.e., for whom it was not compelling). Given signals
                from the literature that inequities may exist, differences in the quality of counteroffer package may differ by
                gender, race, tenure status and discipline.
            </p>
            <p>
                There are some limitations to the calculations of salary and total compensation within these findings. Many
                respondents choose to withhold their home institutions' or outside offers' base salaries. When possible, we
                impute the base salary from campus administrative data, but not all institutions provide such data to COACHE.
            </p>

            <h3>Questions to Consider</h3>
            <ul>
                <li>
                    When a faculty member receives an external offer, how do academic leaders on your campus assess its
                    competitiveness, particularly when base salary seems comparable to the faculty member's current state?
                </li>
                <li>
                    What types of investments (e.g., in graduate student support, in travel that strengthens research networks
                    and know-how) could academic leaders make over the course of a faculty member's career to diminish the
                    attractiveness of outside offers that attempt to lure faculty away with large startup packages comprised of
                    these components?
                </li>
                <li>
                    When multiple faculty are seeking counteroffers and retention funds are limited, what processes exist to
                    prioritize those funds? How does this process ensure that resources are allocated equitably (and not just to
                    those who ask)?
                </li>
            </ul>
            
        </>
    )
}

export default Introduction;