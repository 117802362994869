export interface DefaultFactorCombinations {
    [key: string]: string[];
}

export const defaultFactorCombinations: DefaultFactorCombinations = {
    top_5_most_selected_factors: [],
    compensation: [
        "benefits",
        "department_or_institution_reputation",
        "quality_of_academic_leadership",
        "quality_of_colleagues",
        "salary",
    ],
    faculty_career: [
        "prospects_for_receiving_tenure_or_contract_renewal",
        "security_of_funding",
    ],
    location_and_family: [
        "availability_of_cultural_social_recreational_activities",
        "employment_opportunity_for_spouse_partner_in_region",
        "cost_of_living",
        "proximity_to_family",
        // Institutional policies in support of families  (e.g. childcare, family leave, stop the clock policies, etc.) 
        "quality_of_housing_options",
        "quality_of_schools_for_children",
        "social_political_climate_of_region",
        "tuition_for_dependents",
    ],
    nature_of_work: [
        "alignment_of_institutional_values_to_personal_values",
        "assistance_for_grant_proposals_or_development",
        "number_of_faculty_in_discipline_specializiation",
        "opportunity_to_collaborate_with_colleagues",
        "quality_of_graduate_students",
        "quality_or_quantity_of_research_infrastructure",
        "support_to_improve_my_teaching",
    ],
    work_environment: [
        "campus_environment_faculty_of_color",
        "campus_environment_lgbtq_faculty",
        "campus_environment_women",
        "collegiality_within_department",
        "division_of_time_between_research_teaching_service",
        "potential_for_professional_growth_and_intellectual_stimulation",
        "potential_for_work_life_balance",
        "presence_of_others_like_me", 
        "quality_and_availability_of_mentoring_in_department",
    ]
}