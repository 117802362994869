
import { useParams } from "react-router-dom";

const Introduction = () => {
  const { institution } = useParams();

    return (
        <>
            <h2>What compels faculty to stay? What compels them to leave?</h2>
            <p>
                Before examining what only the faculty eligible for <em>this</em> study can teach us—that is, the <em>costs</em> and <em>conduct</em> of
                retention and departure—we begin at the heart of the matter: what are the <em>causes</em>? The short answer to this
                question is, “It's complicated,” because figuring out <em>how</em> to ask the question is complicated.
            </p>
            <p>
                Our flagship study, the COACHE Faculty Job Satisfaction Survey, recruits hundreds or even thousands of
                faculty at each university who tell us their satisfiers, dissatisfiers, and the strength of their connection to their
                institutions. With an analytic power that comes with such a robust dataset, that study can determine the relative
                predictive strengths of a complex array of factors that coalesce to form an intent to leave or to stay at your
                institution.
            </p>
            <p>
                The COACHE Faculty Retention & Exit Study, however, sacrifices that analytical power to hear the voices of
                those few who have actually left or had a serious opportunity to leave. We know from prior research that
                humans are not very good at unlocking the real reasons for such a decision; we expect to get a reconstruction.
                To manage retrospective sensemaking as best we can, we have taken great care to ask the right questions about
                these push/pull factors, in the right order, and about the right moment in their thought process.
            </p>
            <h2>Survey items</h2>
            <p>
                Early in the instrument, respondents are asked:
            </p>
            <blockquote>
                <em>
                    Think back to the time you received the outside offer, but before any counteroffer was (or was not) made. At that time,
                    what factors were weighing most heavily on your consideration of whether to stay at <span className="red-variable-name">{institution}</span> or accept the outside
                    offer?
                </em>
            </blockquote>
            <p>
                Faculty enter their responses in their own words; we do not prejudice them with a “check all that apply” list of
                likely reasons. Not until that comment is submitted do we present respondents with three questions in
                sequence:
            </p>
            <blockquote>
                <em>
                    From the list below, please rank the top factors that you described were compelling you to stay at <span className="red-variable-name">{institution}</span>.
                </em>
            </blockquote>
            <blockquote>
                <em>
                    Now we would like you to consider compelling factors to accept the outside offer.
                </em>
            </blockquote>
            <blockquote>
                <em>
                    Below are the factors that you identified as compelling in your decision to stay at <span className="red-variable-name">{institution}</span> or accept the outside offer.
                    Please now indicate whether you consider these reasons to be primary or secondary factors in your overall decision-making
                    process
                </em>
            </blockquote>
            <p>
                Together, these four survey items produce the rich (even if redacted) comments and visualizations in these
                reports. These qualitative and quantitative data - after unpacking the results - will help you to better prepare
                your colleagues for those times when a faculty member, letter in hand, knocks on their doors. The data challenge
                academic leaders, who wish to retain their faculty, to look beyond salary levers and to interrogate the nuances
                in faculty's feelings about their institutions, their colleagues, and their careers.
            </p>
            <h2>Why we ask about “compelling factors” at this moment</h2>
            <p>
                This sequence we use is based upon items from several existing surveys and from relevant scholarly literature.
                In many institutions' exit surveys, however, the list of factors is framed in terms of satisfaction. We decided
                that knowing the importance of factors in respondents' decision-making processes is more valuable than
                knowing their satisfaction with various workplace characteristics. After all, the COACHE Faculty Job
                Satisfaction Survey already accomplishes that task.
            </p>
            <p>
                In our pilot study, we realized that this question could ask faculty to recollect their feelings at any number of
                points along the steps in the departure (or retention) process. For example, we could ask faculty to tell us about
                the “factors” they were weighing after a counteroffer was made. While that moment is closer to the point of
                actual decision, it is not a moment that is universally shared; many departures and retentions never receive or
                even seek a counteroffer
            </p>
            <p>
                We found evidence of a better approach in a former study by the University of California at Berkeley, which
                associated the question with the point after outside offer is received, but before any counteroffer is made. At
                this moment, both “stayers” and “leavers” can answer the same question, which was “What factors weighed
                most heavily on your decision to stay or leave?” While this question helps identify factors in play, it does not
                indicate whether the home institution or the recruiting institution is superior on a given factor. Also, Berkeley's
                drop-down menu of choices were too limiting and perhaps even frustrating for respondents
            </p>
            <p>
                Ultimately, we combined and, we believe, improved upon the methods used by Berkeley and by Matier (1990),
                who asked such questions using a “degree of enticement scale,” which allowed for a relative comparison of
                varying factors.
            </p>

            <h2>Why we ask about <em>these</em> factors</h2>
            <p>
                There are factors that faculty think will push them out the door, and those that actually do. O'Meara and her
                colleagues (2014) found that faculty who <em>intended</em> to leave reported they would leave for more prestigious
                departments and better pay. The faculty who <em>actually</em> left were more likely to cite work environment than any
                other factor, even when they appeared to be “moving up” to an institution with greater pay and department
                prestige.
            </p>
            <p>
                Work environment is a predictor of people leaving an institution. Johnsrud and Heck (1994) also identified that
                quality of life issues, such as geographic location and cost of living, were influential reasons for leaving, along
                with tenure pressures and poor relationships within the department. Though prestige of the new department
                or institution is included in only a few institutional surveys that we found, it is discussed by Weiler (1985) and
                O'Meara, Lounder and Campbell (2014).
            </p>
            <p>
                O'Meara and her colleagues (2016) also found that broken (and often implicit) expectations regarding the quality
                of personal relationships and support in the faculty member's department contributed to professors' intent to
                leave. Several other studies included survey items related to the quality of professional relationships in the
                department as well as satisfaction with the intellectual environment, governance, quality of students, and
                opportunities for leadership (Johnsrud & Heck, 1994; Matier, 1990; O'Meara, Lounder et al., 2014; Smart, 1990;
                Weiler, 1985).
            </p>
            <p>
                Promotion, review, and tenure items are generally reported as salient in the literature, which focuses on the
                perceived likelihood of earning tenure and on the quality of promotion and review processes (Johnsrud & Heck,
                1994; O'Meara et al., 2014). Jayakumar, Howard, Allen, and Han (2009) found that dissatisfaction with
                promotion and review processes was an especially salient motivator to leave for faculty of color. Their work
                and other equity-minded research instructs us to include factors that, while less frequently cited overall, could
                be more important to particular populations of interest.
            </p>

            <h2>“Weighing the Factors” visualizations</h2>
            
            <h3>Questions to Consider</h3>
            <ul>
                <li>
                    What factors most explain the differences between faculty who leave and those who choose to stay?
                </li>
                <li>
                    Are there differences in the frequency or importance of factors by demographic characteristics (e.g., gender,
                    race, tenure status, discipline)? Consider differences both between groups (e.g., men vs. women) and within
                    groups beyond your university (e.g., your institution's women vs. the cohort's women).
                </li>
                <li>
                    How can your chairs, deans, and others work with you to assemble a better portfolio of information, not
                    just about the counteroffer, but about all the factors a faculty member weighs in this process? Could you
                    derive a checklist to help chairs and deans in their encounters with faculty who are considering departure?
                </li>
            </ul>
            
        </>
    )
}

export default Introduction;