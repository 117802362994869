const Introduction = () => {

    return (
        <>
            <h3>Brief summary of the COACHE Faculty Retention & Exit Study</h3>
            <p>
                The chief aim of the COACHE Faculty Retention and Exit Study is to assess, in both a qualitative and
                quantitative way, the causes, costs and conduct of faculty retention actions and turnover. Even before the
                results are delivered, we believe that participation challenges provosts, deans, and chairs to reflect critically on
                their retention practices.
            </p>
            <p>
                With data from this study, academic leaders can understand the comparative experiences of full-time faculty
                who receive outside offers and then use those data to prompt informed discussions about the best ways to
                improve faculty retention efforts. We believe that, by acting on the data, presidents, provosts, deans, chairs and
                faculty leaders will make the academy a more attractive and equitable place for talented scholars and teachers
                to work.
            </p>
            <p>
                The primary tool of this study is a web-based survey designed after extensive literature reviews, themes from
                focus group discussions, feedback from senior administrators in academic affairs, and a pilot study with a large
                public university system. Throughout the process of development, administration, and reporting, COACHE
                received assistance from an advisory group of academic leaders who provided critical feedback.
            </p>
            <p>
                While there are many faculty surveys, our instrument is unique in that it was designed expressly to provide a
                national, comparative perspective on the issues affecting faculty mobility at research universities. Yet,
                COACHE results are never comprehensive; as in our other studies, we encourage our university partners to
                extend their sense-making efforts locally with interviews, focus groups, and other means of collective analysis.
            </p>
        </>
    )
}

export default Introduction;