import { FC } from "react";

interface DownloadCsvProps {
    filename: string;
    loading: boolean;
    csvContent: string;
}

const DownloadCSV: FC<DownloadCsvProps> = ({ filename, loading, csvContent }) => {

    const handleDownloadClick = () => {
        // Check if the table is ready
        if (loading) {
            console.error("Table not ready for CSV download");
            return;
        }

        // Create a download link
        const blob = new Blob([csvContent], { type: "text/csv" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = `${filename}.csv`;

        // Append the link to the document body
        document.body.appendChild(link);

        // Initiate the download
        link.click();

        // Revoke the object URL immediately after the download is initiated
        URL.revokeObjectURL(link.href);
    };

    return (
        <div className="csv-button-container">
            <button className="csv-button" onClick={handleDownloadClick} disabled={loading}>
                {loading ? "Loading..." : "Download CSV"}
            </button>
        </div>
    )
}

export default DownloadCSV;