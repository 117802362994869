const Subsection1 = () => {
    return (
        <>

            <h3>Eligible population</h3>
            <p>
                All eligible subjects at participating institutions were invited to complete the survey. Eligibility was determined
                according to the following criteria:
            </p>
            <h4 className="italicize-sub-header">Appointment types</h4>
            <ul>
                <li>
                    Full-time; tenure-stream; assistant, associate and full professors
                </li>
                <li>
                    (Optional) Full-time; non-tenure-track; multi-year appointment faculty (e.g., “senate” or “voting” faculty)
                </li>
            </ul>
            <h4 className="italicize-sub-header">Employment status</h4>
            <ul>
                <li>
                    Was employed at institution in the prior academic year (July-June) but was no longer employed by the
                    institution at the start of the academic year in which the survey was administered (the “departures”).
                </li>
                <li>
                    Renegotiated terms of employment in the prior year (July-June) as a result of an outside offer (the
                    “retentions”)
                </li>
                <li>
                    Received preemptive retention actions (the “preemptives”) in the prior year (July-June) without
                    presenting an outside offer, if known.
                </li>
            </ul>
            <h4 className="italicize-sub-header">Separation types</h4>
            <ul>
                <li>
                    Voluntary resignations
                </li>
                <li>
                    Retirements who go on to continued full-time employment in the academy (if known)
                </li>
            </ul>
            <h4 className="italicize-sub-header">Additional exclusions</h4>
            <ul>
                <li>
                    Faculty in their terminal year after being denied tenure
                </li>
                <li>
                    Involuntary separations (including confidentially negotiated settlements and tenure denials)
                </li>
                <li>
                    Senior administrators, e.g., Dean, Assistant Dean, Associate Provost (but chairs may be included)
                </li>
                <li>
                    “Natural” retirements -- those who are not engaged in comparable employment elsewhere
                </li>
                <li>
                    Faculty who departed or were retained prior to July 1 of the most recently completed academic year, even
                    if their official separation date may be within the eligible range
                </li>
            </ul>
        </>
    )
}

export default Subsection1;