import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { TextResponse } from "../../models/responseData";
import { cleanString } from "../../utils/formatString";
import { determineResponseTypeTextResponse } from "../../utils/determineResponseType";

const Subsection3 = () => {
  const [transformedResponse, setTransformedResponse] = useState<TextResponse | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { year, institution } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section9/subsection3/?report_year=${year}&group_by=exit_status`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTextResponse(data)) {
        setTransformedResponse(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently, year]);

  return (
    <>
      <h2>9.3 Additional details about actions taken preemptively to retain faculty (Open-text Response)</h2>
      <h3>What additional details might we learn about the circumstances of preemptive retention
        actions?</h3>
      <div className="grid">
        <div className="grid-row-title">
          <em>Prompt:</em>
        </div>
        <div>
          Please provide any additional details you wish to share about recent changes to your work or employment at <span className="red-variable-name">{institution}</span>.
        </div>
        <div className="grid-row-title">
          <em>Paths:</em>
        </div>
        <div>
          Preemptive retentions
        </div>
      </div>
      
      <h4>Preemptive Retention</h4>
      {transformedResponse && transformedResponse.institution.preemptive_retention &&
        <>
          {transformedResponse.institution.preemptive_retention.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.preemptive_retention.map((response: string, i: number) =>
                <li key={i}>{cleanString(response)}</li>
              )}
            </ul>
          }
        </>
      }

      
    </>
  )
}

export default Subsection3;