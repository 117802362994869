import { useEffect } from "react";
import { convertTableToCSV } from "./convertTableToCsv";
import DownloadCSV from "../../components/DownloadCsv";

interface TableToCSVConverterProps {
    table: HTMLTableElement | null;
    filename: string;
    loading: boolean;
    setLoading: (loading: boolean) => void;
    section?: string;
}

const TableToCSVConverter: React.FC<TableToCSVConverterProps> = ({ table, filename, loading, setLoading, section }) => {
    const csvContent = convertTableToCSV(table, section);
    
    useEffect(() => {
        // Update loading state when the table is ready
        if (table) {
            setLoading(false);
        }
    }, [setLoading, table]);

    return (
        <DownloadCSV 
            filename={filename}
            loading={loading}
            csvContent={csvContent}
        />
    );
};

export default TableToCSVConverter;

