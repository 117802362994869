import { FC, useEffect, useRef } from "react";
import * as Plot from "@observablehq/plot";
import * as d3 from "d3";
import { Categories, getModifedCategories } from "../../utils/legendMapping/categoryLegend";
import { INSUFFICIENT_N } from "../../utils/constants";
import { getSymbolMarkupWithStroke } from "../shared/symbolColorLegendUtils";
import { formatLabels } from "../../utils/utils";

interface TransformedDataForHistogram {
    type: string;
    category: string;
    factor: string;
    ranking: string;
    frequency: number;
}

interface OverlappingHistogramProps {
    data: TransformedDataForHistogram[];
    selectedFilter: string;
    institutionN: number;
    factorOrder: any[];
    reason: string;
}


const Legend = () => {

    const legendRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {

        if (legendRef.current) {
            legendRef.current.innerHTML = "";
            const legend = d3.select(legendRef.current).append("div").style("marginTop", "20px");

            const institutionLegend = legend
                .append("div")
                .style("display", "flex")

            const resultLegendData = [
                {
                    fillColor: "#A5BBC3",
                    strokeColor: "#175C90",
                    label: "Your Institution",
                    symbol: "circle"
                },
                {
                    strokeColor: "#000000",
                    label: "Cohort",
                    symbol: "circle"
                }
            ]

            institutionLegend
                .selectAll("div")
                .data(resultLegendData)
                .enter()
                .append("div")
                .style("display", "flex")
                .style("align-items", "center")
                .style("margin-right", "20px")
                .style("margin-left", "20px")
                .html(d => `
                    ${getSymbolMarkupWithStroke(d)}
                    <span style="margin-left: 5px;">${d.label}</span>
                `)
                ;
        }
    })
    return (
        <div ref={legendRef} className="legend-container" />
    )
};


const OverlappingHistogram: FC<OverlappingHistogramProps> = ({ 
    data, 
    selectedFilter, 
    institutionN, 
    factorOrder, 
    reason 
}) => {
    const plotContainerRef = useRef<HTMLDivElement | null>(null);

    const factorOrderDomain = factorOrder.map((factor) => formatLabels(factor));

    useEffect(() => {
        let chart;
        if (plotContainerRef.current && data) {
            plotContainerRef.current.innerHTML = "";

            let modifiedCategories = getModifedCategories();
            let currentCategories = modifiedCategories[selectedFilter as keyof Categories];

            for (const category of currentCategories) {
                const validCategories = data.filter((item) => item.category === category);
                if (validCategories.length > 0) {
                    chart = Plot.plot({
                        marks: [
                            Plot.frame(),
                            Plot.areaY(data.filter((item) => item.type === "Your Institution" && institutionN > INSUFFICIENT_N && item.category === category), {
                                x: d => Number(d.ranking),
                                y: "frequency",
                                fy: "factor",
                                fill: "#A5BBC3",
                                stroke: "#175C90",
                                strokeWidth: 1,
                                curve: "step"
                            }),

                            Plot.line(data.filter((item) => item.type === "Cohort" && item.category === category), {
                                x: d => Number(d.ranking),
                                y: "frequency",
                                fy: "factor",
                                stroke: "black",
                                strokeWidth: 2,
                                curve: "step"
                            }),
                            Plot.axisFy({
                                lineWidth: 10,
                                anchor: "left",
                                tickPadding: 35,
                                label: null
                            }),
                            Plot.text(data.filter((item: any) => item.type === "Your Institution" && item.category === category), {
                                fy: "factor",
                                text: d => `n = ${d.total}`,
                                frameAnchor: "top-left",
                                dx: -75,
                                dy: 90
                            }),
                        ],
                        x: {
                            ticks: [],
                            label: "Most important",
                            labelArrow: "right",
                            reverse: true // reverse graph
                        },
                        y: {
                            label: "Frequency of factor selection (%)",
                            domain: [0, 100],
                            labelAnchor: "top",
                            percent: true
                        },
                        fy: {
                            domain: factorOrderDomain
                        },
                        title: category,
                        width: 350,
                        marginLeft: 145,
                        grid: true,
                        style: {
                            fontSize: "12px",
                        },
                    });
                    chart.classList.add("chart-container");
                    plotContainerRef.current.appendChild(chart);
                }
            }
        }

    }, [data, selectedFilter, reason, factorOrderDomain, institutionN])

    return (
        <>
            <Legend />
            <div ref={plotContainerRef} className="plot-container" />
        </>
    )
}

export default OverlappingHistogram;