import { Fragment, useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import FilterDropdown from "../../components/FilterDropdown";
import InstitutionN from "../../components/InstitutionN";
import { determineNdisplay, roundNumber, formatLabels } from "../../utils/utils";
import TableToCSVConverter from "../../utils/csvDownload/TableToCsvConverter";
import PageLoader from "../../components/PageLoader";
import { getModifedCategoriesVarNames, Categories, getNameFromVariable } from "../../utils/legendMapping/categoryLegend";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";

const CounterofferNotification = () => {
  const [selectedFilter, setSelectedFilter] = useState("exit_status");
  const [responseA, setResponseA] = useState<any>();
  const [responseB, setResponseB] = useState<any>();
  const [institutionNresponseA, setInstitutionNresponseA] = useState(0);
  const [institutionNresponseB, setInstitutionNresponseB] = useState(0);
  const tableRefA = useRef<HTMLTableElement>(null);
  const tableRefB = useRef<HTMLTableElement>(null);
  const [tableA, setTableA] = useState<HTMLTableElement | null>(null);
  const [tableB, setTableB] = useState<HTMLTableElement | null>(null);
  const [loadingA, setLoadingA] = useState(true);
  const [loadingB, setLoadingB] = useState(true);
  let allCategoriesDict: Categories = getModifedCategoriesVarNames();
  allCategoriesDict["exit_status"] = ["overall", "departure", "retention"];

  const [validCategoriesSectionA, setValidCategoriesSectionA] = useState(allCategoriesDict["exit_status"]);
  const [validCategoriesSectionB, setValidCategoriesSectionB] = useState(allCategoriesDict["exit_status"]);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getDataA = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section5/subsection3a/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setResponseA(data);
        setInstitutionNresponseA(data.institution.overall.count);
        setLoadingA(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataB = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section5/subsection3b/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setResponseB(data);
        setInstitutionNresponseB(data.institution.overall.count);
        setLoadingB(false);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataA();
    getDataB();

  }, [getAccessTokenSilently, selectedFilter, year]);

  useEffect(() => {
    if (tableRefA.current) {
      setTableA(tableRefA.current);
    }
  }, [responseA]);

  useEffect(() => {
    if (tableRefB.current) {
      setTableB(tableRefB.current);
    }
  }, [responseB]);

  const getValidCategoriesOverallFilter = (validCategories: any[], setValidCategories: any, data: { [x: string]: { count: number; total: number; }; }) => {

    let showTotal = false;
    let allCategoriesRedacted = true;
    let allCategoriesShown = true;

    validCategories.forEach((category) => {
      if (category !== "total") {
        if (data[category].count < 5 || data[category].total < 5) {
          allCategoriesShown = false;
        }
        if (data[category].count >= 5 || data[category].count >= 5) {
          allCategoriesRedacted = false;
        }
      }
    })

    // show total data if either all exit category data is redacted or none of category data is redacted
    if (allCategoriesRedacted || allCategoriesShown) {
      showTotal = true;
    }
    if (!showTotal) {
      setValidCategories(validCategories.filter((category) => category !== "overall"))
    }
  }

  // section A valid exit status categories
  useEffect(() => {
    if (responseA && selectedFilter === "exit_status") {
      getValidCategoriesOverallFilter(
        validCategoriesSectionA,
        setValidCategoriesSectionA,
        responseA["institution"]
      )
    }
  }, [responseA, selectedFilter, validCategoriesSectionA])

    // section B valid exit status categories
    useEffect(() => {
      if (responseB && selectedFilter === "exit_status") {
        getValidCategoriesOverallFilter(
          validCategoriesSectionB,
          setValidCategoriesSectionB,
          responseB["institution"]
        )
      }
    }, [responseB, selectedFilter, validCategoriesSectionB])

  useEffect(() => {
    setValidCategoriesSectionA(allCategoriesDict[selectedFilter as keyof Categories]);
    setValidCategoriesSectionB(allCategoriesDict[selectedFilter as keyof Categories]);
  }, [allCategoriesDict, selectedFilter])

  return (
    <>
      <h2>5.3 Number of days from notification to counteroffer</h2>

      <FilterDropdown setSelectedFilter={setSelectedFilter} />

      <h4>Number of days from notification to counteroffer</h4>
      <p>
        Approximately how many days transpired from the day your institution learned of your outside offer to the day that you received an official
        (e.g. written) counteroffer?
      </p>

      {!responseA && <PageLoader />}
      {responseA &&
        <>
          <InstitutionN institutionN={institutionNresponseA} />
          <div className="data-table-container">
            <table ref={tableRefA}>
              <thead>
                <tr>
                  <th></th>
                  {
                    validCategoriesSectionA.map((category, i) =>
                      <th key={i} colSpan={6}>{getNameFromVariable(selectedFilter, category)}</th>
                    )}
                </tr>
                <tr className="column-headers">
                  <th className="row-header"></th>
                  {validCategoriesSectionA.map((category, i) =>
                    <Fragment key={i}>
                      <th>Count</th>
                      <th>Mean</th>
                      <th>SD</th>
                      <th>Min</th>
                      <th>Median</th>
                      <th className="row-split">Max</th>
                    </Fragment>
                  )}
                </tr>
              </thead>
              <tbody>
                {Object.keys(responseA).map((type, i) =>
                  <tr key={i}>
                    <th className="row-header">{formatLabels(type)}</th>
                    {validCategoriesSectionA.map((category, i) => {
                      let current = responseA[type][category];

                      if (current && current.count) {
                        return (
                          <Fragment key={i}>
                            <td>{determineNdisplay(current.count, true, true)}</td>
                            <td>{roundNumber(current.mean)}</td>
                            <td>{roundNumber(current.stdev, true)}</td>
                            <td>{roundNumber(current.min)}</td>
                            <td>{(roundNumber(current.median))}</td>
                            <td className="row-split">{roundNumber(current.max)}</td>
                          </Fragment>
                        )
                      }
                      return (
                        <Fragment key={i}>
                          <td>{"n < 5"}</td>
                          <td>{"n < 5"}</td>
                          <td>{"n < 5"}</td>
                          <td>{"n < 5"}</td>
                          <td>{"n < 5"}</td>
                          <td className="row-split">{"n < 5"}</td>
                        </Fragment>)
                    })}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <TableToCSVConverter
            table={tableA}
            filename="5.3_Number_of_days_from_notification_to_counteroffer"
            loading={loadingA}
            setLoading={setLoadingA}
          />
        </>
      }


      <h4>Number of days from notification to the news that no counteroffer is forthcoming</h4>
      <p>
        Approximately how many days transpired from the day your institution learned of your outside offer to the day that you learned that you would not receive an official counteroffer?
      </p>

      {!responseB && <PageLoader />}
      {responseB &&
        <>
          <InstitutionN institutionN={institutionNresponseB} />
          <div className="data-table-container">
            <table ref={tableRefB}>
              <thead>
                <tr>
                  <th></th>
                  {validCategoriesSectionB.map((category, i) =>
                    <th key={i} colSpan={6}>{getNameFromVariable(selectedFilter, category)}</th>
                  )}
                </tr>
                <tr className="column-headers">
                  <th className="row-header"></th>
                  {validCategoriesSectionB.map((category, i) =>
                    <Fragment key={i}>
                      <th>Count</th>
                      <th>Mean</th>
                      <th>SD</th>
                      <th>Min</th>
                      <th>Median</th>
                      <th className="row-split">Max</th>
                    </Fragment>
                  )}
                </tr>
              </thead>
              <tbody>
                {Object.keys(responseB).map((type, i) =>
                  <tr key={i}>
                    <th className="row-header">{formatLabels(type)}</th>
                    {validCategoriesSectionB.map((category, i) => {
                      let current = responseB[type][category];

                      if (current && current.count) {
                        return (
                          <Fragment key={i}>
                            <td>{determineNdisplay(current.count, true, true)}</td>
                            <td>{roundNumber(current.mean)}</td>
                            <td>{roundNumber(current.stdev, true)}</td>
                            <td>{roundNumber(current.min)}</td>
                            <td>{(roundNumber(current.median))}</td>
                            <td className="row-split">{roundNumber(current.max)}</td>
                          </Fragment>
                        )
                      }
                      return (
                        <Fragment key={i}>
                          <td>{"n < 5"}</td>
                          <td>{"n < 5"}</td>
                          <td>{"n < 5"}</td>
                          <td>{"n < 5"}</td>
                          <td>{"n < 5"}</td>
                          <td className="row-split">{"n < 5"}</td>
                        </Fragment>)
                    })}
                  </tr>
                )}
              </tbody>
            </table>
          </div>
          <TableToCSVConverter
            table={tableB}
            filename="5.3_Number_of_days_from_notification_to_no_counteroffer"
            loading={loadingB}
            setLoading={setLoadingB}
          />
        </>
      }


    </>
  )
}

export default CounterofferNotification;