import { formatString } from "../formatString";

export const makeTopFactorsLegend = (topFactors: any[]) => {
    let legend: Record<string, string> = {}
    topFactors.forEach((factor) => {
        legend[factor] = formatString(factor)
    })
    const sortedFactors = Object.keys(legend).sort();
    return sortedFactors.reduce((result: any, key: any) => {
        result[key] = legend[key];
        return result;
    }, {});
}