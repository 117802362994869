import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Introduction from "./Introduction";
import SubSection1 from "./Subsection1";
import SubSection2 from "./Subsection2";
import SubSection3 from "./Subsection3";
import SubSection4 from "./Subsection4";
import Subsection4b from "./Subsection4b";
import SubSection5 from "./Subsection5";
import Subsection5b from "./Subsection5b";
import SubSection6 from "./Subsection6";
import Subsection6b from "./Subsection6b";
import SubSection7 from "./Subsection7";
import Subsection8 from "./Subsection8";
import Subsection9 from "./Subsection9";
import BreadcrumbNav from "../../navigation/Breadcrumb";
import BottomNavigation from "../../navigation/BottomNavigation";

interface NegotiationTermsProps {
  toggleSection: (section: string, open?: boolean) => void;
}

const NegotiationTerms: FC<NegotiationTermsProps> = ({ toggleSection }) => {
  const { subsection } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsection]);

  useEffect(() => {
    toggleSection("The Negotiation Terms", true);
  }, [toggleSection])

  useEffect(() => {
    if (subsection === "PermissibleUseStartupFund") {
      toggleSection("Negotiation Terms/Permissible Use Startup Fund", true);
    }

    if (subsection === "CounterOffersSoughtReceived") {
      toggleSection("Negotiation Terms/Counter Offers Sought Received", true);
    }

    if (subsection === "YourCounterofferProposedChanges") {
      toggleSection("Negotiation Terms/Proposed Changes", true);
    }
  }, [toggleSection, subsection])

  return (
    <>
      <BreadcrumbNav section="NegotiationTerms" subsection={subsection} />

      <h1>The Negotiation Terms</h1>
      {subsection === "Introduction" && <Introduction />}
      {subsection === "OriginOutsideOffers" && <SubSection1 />}
      {subsection === "RankTenureTitle" && <SubSection2 />}
      {subsection === "Compensation" && <SubSection3 />}
      {subsection === "PermissibleUseStartupFund" && <SubSection4 />}
      {subsection === "ResponsesToOtherPromptsPermissibleUseStartupFund" && <Subsection4b />}
      {subsection === "CounterOffersSoughtReceived" && <SubSection5 />}
      {subsection === "ResponsesToOtherPromptsCounterOffersSoughtReceived" && <Subsection5b />}
      {subsection === "YourCounterofferProposedChanges" && <SubSection6 />}
      {subsection === "ResponsesToOtherPromptsYourCounterofferProposedChanges" && <Subsection6b />}
      {subsection === "YourCounterofferExtentMatched" && <SubSection7 />}
      {subsection === "YourCounterofferFacultyAssessmentOfComparison" && <Subsection8 />}
      {subsection === "YourCounterofferWhyItDidNotMaterialize" && <Subsection9 />}

      <BottomNavigation />
    </>
  )
}

export default NegotiationTerms;