import { FC } from "react";
import { Breadcrumbs, Link as MuiLink, Typography } from "@mui/material";
import { Link as ReactRouterLink, useParams, useLocation } from "react-router-dom";
import { formatBreadcrumbLabel } from "../utils/navUtils";
import { formatString } from "../utils/formatString";

interface BreadcrumbNavProps {
    section: any;
    subsection?: any;
    onAboutPage?: boolean;
}

const BreadcrumbNav: FC<BreadcrumbNavProps> = ({ section, subsection, onAboutPage }) => {

    const { reportType, year } = useParams();
    let formattedReportType;
    if (reportType) {
        formattedReportType = formatString(reportType);
    }

    let sectionBasePage = "Introduction";
    if (section === "Appendix") {
        sectionBasePage = "BriefSummary";
    }

    const location = useLocation();
    const splitPath = location.pathname.split("/");
    const path = splitPath[1] + "/" + splitPath[2];

    return (
        <div className="breadcrumbs">
            {onAboutPage ?
                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <MuiLink underline="hover" color="inherit" href="/">
                        Report Home
                    </MuiLink>

                    <Typography
                        color="text.primary"
                        aria-current="page"
                    >
                        Exit Retention {year} {formattedReportType} Report
                    </Typography>
                </Breadcrumbs> :

                <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <MuiLink underline="hover" color="inherit" href="/">
                        Report Home
                    </MuiLink>
                    <MuiLink
                        underline="hover"
                        color="inherit"
                        component={ReactRouterLink}
                        to={`/${path}/${reportType}/${year}/AboutThisReport`}
                    >
                        Exit Retention {year} {formattedReportType} Report
                    </MuiLink>
                    <MuiLink
                        underline="hover"
                        color="inherit"
                        component={ReactRouterLink}
                        to={`/${path}/${reportType}/${year}/${section}/${sectionBasePage}`}
                    >
                        {formatBreadcrumbLabel(section)}
                    </MuiLink>
                    <Typography
                        color="text.primary"
                        aria-current="page"
                    >
                        {formatBreadcrumbLabel(subsection)}
                    </Typography>
                </Breadcrumbs>
            }
        </div>
    )
}

export default BreadcrumbNav;