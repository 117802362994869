export const formatString = (str: string) => {
    return (
        str
            .split("_")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" "))
}

export const cleanString = (str: string) => {
    // Define a regular expression to match non-normal characters
    const regex = /[^\w\s\d.,'";:?!()/[\]{}<>%^&*_=+\\|~-]/g;

    // Replace non-normal characters with an empty string
    return (str.replace(regex, ""));
}