import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Introduction from "./Introduction";
import SubSection1 from "./Subsection1";
import Subsection1b from "./Subsection1b";
import SubSection2 from "./Subsection2";
import SubSection3 from "./Subsection3";
import SubSection4 from "./Subsection4";
import TextBasedResponse from "./Subsection5";
import BreadcrumbNav from "../../navigation/Breadcrumb";
import BottomNavigation from "../../navigation/BottomNavigation";

interface OverallImpressionsProps {
  toggleSection: (section: string, open?: boolean) => void;
}

const OverallImpressions:FC<OverallImpressionsProps> = ({ toggleSection }) => {
  const { subsection } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsection]);

  useEffect(() => {
    toggleSection("Overall Impressions", true);
  }, [toggleSection])

  useEffect(() => {
    if (subsection === "TopChange") {
      toggleSection("Overall Impressions/Top Change", true);
    }
  }, [toggleSection, subsection])

  return (
    <>
      <BreadcrumbNav section="OverallImpressions" subsection={subsection} />

      <h1>Overall Impressions</h1>
      {subsection === "Introduction" && <Introduction />}
      {subsection === "TopChange" && <SubSection1 />}
      {subsection === "ResponsesToOtherPromptsTopChange" && <Subsection1b />}
      {subsection === "Satisfaction" && <SubSection2 />}
      {subsection === "Recommendation" && <SubSection3 />}
      {subsection === "Likelihood" && <SubSection4 />}
      {subsection === "TextBasedResponses" && <TextBasedResponse />}
      
      <BottomNavigation />
    </>
  )
}

export default OverallImpressions;