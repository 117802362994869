import * as Plot from "@observablehq/plot";
import {
    fiveSequential,
    fourSequential,
    sevenSequential,
    sixSequential,
    stackedBarChartColorScheme,
    COLORS_NEEDING_WHITE_TEXT
} from "./colorScheme";

export const determineFill = (d: any, sequential?: any, colorScale?: any, domain?: any) => {
    if (sequential) {
        const textColors = colorScale.range.map((colorCode: any) => {
            if (COLORS_NEEDING_WHITE_TEXT.includes(colorCode)) {
                return "white";
            }
            return "black";
        }
        );
        let domainIndex = 0;
        if (d.description) {
            domainIndex = domain.indexOf(d.description);
        }
        if (d.answerChoice) {
            domainIndex = domain.indexOf(d.answerChoice);
        }
        return textColors[domainIndex];
    } else {
        // TODO: fix category names and make more consistent
        if (domain.indexOf(d.category) === 0 && colorScale.range[0] === "#4978AA") {
            return "white"
        }
        if (domain.indexOf(d.description) === 0 && colorScale.range[0] === "#4978AA") {
            return "white"
        }
        if (domain.indexOf(d.respondent_status) === 0 && colorScale.range[0] === "#4978AA") {
            return "white"
        }
        if (domain.indexOf(d.retention_status) === 0 && colorScale.range[0] === "#4978AA") {
            return "white"
        }
        return "black";
    }
}

export const getColorScale = (sequential: any, domain: any) => {
    let colorScale: Plot.ScaleOptions;

    if (sequential) {
        let range = sevenSequential;
        if (domain.length === 4) {
            range = fourSequential;
        } else if (domain.length === 5) {
            range = fiveSequential;
        } else if (domain.length === 6) {
            range = sixSequential;
        }

        colorScale = {
            type: "categorical",
            legend: true,
            domain: domain,
            range: range
        }
    } else {
        colorScale = {
            type: "categorical",
            legend: true,
            domain: domain,
            range: stackedBarChartColorScheme
        }
    }
    return colorScale;
}

export const determineTextLabelDisplay = (d: any, display: any, selector?: any) => {
    let value;
    if (d.value) {
        if (display === "symbol") {
            return `${d.Category}: ${d["% Change to Counteroffer"]}%`
        }

        value = Math.round(d.value * 10000) / 100;
        if (selector) {
            if ((display === "label" && value >= 5) || (display === "tooltip")) {
                if (d[selector] && display === "tooltip") {
                    return `${d[selector]}: ${value}%`
                }
                return `${value}%`
            }
        }
    }
    return;
}