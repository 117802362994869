import { FC, useEffect, useRef } from "react";
import * as Plot from "@observablehq/plot";
import { determineTextLabelDisplay, determineFill } from "../shared/colorSchemeUtils";
import { stackedBarChartColorScheme } from "../shared/colorScheme";
import { makeCategoryNDict } from "../../utils/utils";
import { defaultXscaleBarChart } from "../shared/observablePlotUtils";

const respondentCountsDomain = [
    "Non Respondent - Departure", 
    "Non Respondent - Retention", 
    "Non Respondent - Preemptive Retention", 
    "Respondent - Departure", 
    "Respondent - Retention",
    "Respondent - Preemptive Retention"
]

interface StackedByRetentionRespondentStatusProps {
    data: any[];
    selectedFilter: string;
}

const StackedByRetentionRespondentStatus: FC<StackedByRetentionRespondentStatusProps> = ({ data, selectedFilter }) => {
    const plotContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let chart;
        let colorScale = {
            type: "categorical",
            legend: true,
            range: stackedBarChartColorScheme,
            domain: respondentCountsDomain,
        }
        const categoryNdict = makeCategoryNDict(selectedFilter, data,"description", "No Total")

        if (plotContainerRef.current && data) {
            plotContainerRef.current.innerHTML = "";
            chart = Plot.plot({
                marks: [
                    Plot.barX(data, {
                        x: "value",
                        y: "description",
                        fy: "type",
                        fill: "respondent_status",
                        sort: {
                            fy: { value: "y", order: null },
                            y: { value: "y", order: null }
                        },
                        insetTop: 0.2,
                        insetBottom: 0.2,
                        title: (d) => determineTextLabelDisplay(d, "tooltip", "respondent_status"),
                    }),
                    Plot.textX(data,
                        Plot.stackX({
                            text: d => determineTextLabelDisplay(d, "label", "respondent_status"),
                            x: "value",
                            y: "description",
                            fy: "type",
                            fill: d => determineFill(d, false, colorScale, respondentCountsDomain),
                        })),
                    Plot.text(data.filter((item: any) => item.type === "Your Institution"), {
                        y: "description",
                        fy: "type",
                        text: d => {
                            if (d.total === categoryNdict[d.description] && d.total !== 0)
                                return `n = ${d.total}`
                        },
                        frameAnchor: "top-left",
                        dx: -200,
                        dy: -5
                    }),
                    Plot.axisFy({
                        lineWidth: 6,
                        anchor: "left",
                        tickPadding: 220,
                        label: null,
                        fontWeight: "bold"
                    }),
                ],
                x: {
                    ...defaultXscaleBarChart,
                    domain: [0, 1],
                },
                y: { label: null },
                width: 800,
                marginLeft: 285,
                color: {
                    type: "categorical",
                    legend: true,
                    range: stackedBarChartColorScheme,
                    domain: respondentCountsDomain,
                },
                style: {
                    fontSize: "12px"
                }
            });
            plotContainerRef.current.appendChild(chart);
        }
    }, [data, selectedFilter])

    return (
        <div ref={plotContainerRef} />
    )
}

export default StackedByRetentionRespondentStatus;