import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { OtherTextResponseStayLeave } from "../../models/responseData";
import { determineResponseTypeOtherTextStayLeave } from "../../utils/determineResponseType";

const ResponsesToOtherPrompts = () => {
  const [transformedResponse, setTransformedResponse] = useState<OtherTextResponseStayLeave | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { year, institution } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section2/other/?report_year=${year}`;

      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeOtherTextStayLeave(data)) {
        setTransformedResponse(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently, year]);

  return (
    <>
      <h2>Responses to “Other (please specify)”</h2>

      <h3>From the list below, please rank the top factors that you described were compelling you to <span className="underline">stay at <span className="red-variable-name">{institution}</span></span>.</h3>

      {transformedResponse && transformedResponse.institution.stay &&
        <>
          {transformedResponse.institution.stay.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.stay.map((response: string, i: number) =>
                <li key={i}>{response}</li>
              )}
            </ul>
          }
        </>
      }

      <h3>From the list below, please rank the top factors that you described were compelling you to <span className="underline">accept the outside offer.</span></h3>

      {transformedResponse && transformedResponse.institution.leave &&
        <>
          {transformedResponse.institution.leave.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.leave.map((response: string, i: number) =>
                <li key={i}>{response}</li>
              )}
            </ul>
          }
        </>
      }

      
    </>
  )
}

export default ResponsesToOtherPrompts;