import { FC, useEffect, useRef } from "react";
import * as Plot from "@observablehq/plot";
import { TransformedData } from "../../utils/transformDataUtils";
import { determineFill, getColorScale, determineTextLabelDisplay } from "../shared/colorSchemeUtils";
import { getInstitutionCohortDomain } from "../shared/getInstitutionCohortDomain";
import { retentionCategoriesMap } from "../../utils/legendMapping/categoryLegend";
import { defaultXscaleBarChart, plotStyle } from "../shared/observablePlotUtils";
import { makeCategoryNDict } from "../../utils/utils";

interface StackedByRetentionProps {
  data: TransformedData[];
  sequential?: boolean;
  institutionN: number;
  selectedFilter: string;
}
const retentionCategories = Object.values(retentionCategoriesMap);

const StackedByRetention: FC<StackedByRetentionProps> = ({ data, sequential, institutionN, selectedFilter }) => {
  const plotContainerRef = useRef<HTMLDivElement | null>(null);
  useEffect(() => {
    let chart;

    const fillDomain = retentionCategories;
    let colorScale = getColorScale(sequential, fillDomain);
    const yDomain = getInstitutionCohortDomain(institutionN);

    const categoryNdict = makeCategoryNDict(selectedFilter, data, "description", "Preemptive Retention")

    if (plotContainerRef.current && data) {
      plotContainerRef.current.innerHTML = "";
      chart = Plot.plot({
        marks: [
          Plot.barX(data, {
            x: "value",
            y: "type",
            fy: "description",
            fill: "category",
            sort: {
              fy: { value: "y", order: null },
              y: { value: "y", order: null }
            },
            insetTop: 0.2,
            insetBottom: 0.2,
            title: (d) => determineTextLabelDisplay(d, "tooltip", "category"),
            offset: "normalize"
          }),
          Plot.textX(data,
            Plot.stackX({
              text: d => determineTextLabelDisplay(d, "label", "category"),
              x: "value",
              y: "type",
              fy: "description",
              fill: d => determineFill(d, false, colorScale, fillDomain)
            })),
          Plot.axisFy({
            lineWidth: 8,
            anchor: "left",
            tickPadding: 155,
            label: null,
            fontWeight: "bold"
          }),
          Plot.text(data.filter((item: any) => item.type === "Your Institution"), {
            y: "type",
            fy: "description",
            text: d => {
              if (d.total === categoryNdict[d.description] && d.total !== 0)
                return `n = ${d.total}`
            },
            frameAnchor: "top-left",
            dx: -140,
            dy: 5
          }),
        ],
        x: defaultXscaleBarChart,
        y: {
          label: null,
          domain: yDomain
        },
        fy: {
          label: null,
        },
        width: 800,
        marginLeft: 210,
        color: colorScale,
        style: plotStyle,
      });
      plotContainerRef.current.appendChild(chart);
    }

  }, [data, institutionN, selectedFilter, sequential])

  return (
    <div ref={plotContainerRef} />
  )
}

export default StackedByRetention;