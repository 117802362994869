import axios, { AxiosRequestConfig } from "axios";
import { ApiResponse } from "../models/apiResponse";
import { callExternalApi } from "./externalApi.service";

const apiServerUrl = process.env.REACT_APP_API_SERVER_URL;

export const getProtectedResource = async (
  accessToken: string,
  requestUrl: string,
): Promise<ApiResponse> => {
  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/${requestUrl}`,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`
    },
  };

  const { data, error } = (await callExternalApi({ config })) as ApiResponse;

  return {
    data,
    error,
  };
};

export const updateResource = async (
  accessToken: string,
  requestUrl: string,
  updatedData: any
): Promise<number> => {

  const config: AxiosRequestConfig = {
    url: `${apiServerUrl}/${requestUrl}`,
    method: "PATCH",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${accessToken}`,
    },
    data: updatedData,
  };

  try {
    const response = await axios(config);

    // Return the HTTP status code
    return response.status;
  } catch (error) {
    if (axios.isAxiosError(error)) {
      console.error("Error updating resource:", error);

      // Return an appropriate status code (e.g., 500 for internal server error)
      return error.response?.status || 500;
    }
  }
  return 500;
};