import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Introduction from "./Introduction";
import DemographicCharacteristics from "./Subsection1";
import InstitutionalPersistence from "./Subsection2";
import ResponseRates from "./Subsection3";
import BreadcrumbNav from "../../navigation/Breadcrumb";
import BottomNavigation from "../../navigation/BottomNavigation";


interface TheAnalyticSampleProps {
  toggleSection: (section: string, open?: boolean) => void;
}

const TheAnalyticSample: FC<TheAnalyticSampleProps> = ({ toggleSection }) => {
  const { subsection } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsection]);

  useEffect(() => {
    toggleSection("The Analytic Sample", true);
  }, [toggleSection])

  return (
    <>
      <BreadcrumbNav section="TheAnalyticSample" subsection={subsection} />

      <h1>The Analytic Sample</h1>
      {subsection === "Introduction" && <Introduction />}
      {subsection === "DemographicCharacteristics" && <DemographicCharacteristics />}
      {subsection === "InstitutionalPersistence" && <InstitutionalPersistence />}
      {subsection === "ResponseRates" && <ResponseRates />}

      <BottomNavigation />
    </>
  )
}

export default TheAnalyticSample;