import { FC } from "react";

interface HeaderProps {
    institutionName: string;
}

const Header: FC<HeaderProps> = ({ institutionName }) => {

    return (
        <h1 className="header">
            {institutionName}
        </h1>
    )
}

export default Header;