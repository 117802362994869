import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { TextResponse } from "../../models/responseData";
import { cleanString } from "../../utils/formatString";
import { determineResponseTypeTextResponse } from "../../utils/determineResponseType";


const Subsection8 = () => {
  const [transformedResponse, setTransformedResponse] = useState<TextResponse | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {
    const getData = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section6/subsection8/?report_year=${year}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTextResponse(data)) {
        setTransformedResponse(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getData();
  }, [getAccessTokenSilently, year]);

  return (
    <>
      <h2>6.8 Your counteroffer: Faculty assessment of comparison (Open-text Response)</h2>
      <h3>How do faculty describe, in their own words, how your counteroffers compare to outside offers?</h3>

      <div className="grid">
        <div className="grid-row-title">
          <em>Prompt:</em>
        </div>
        <div>
          Please provide any additional details about how the counteroffer compared to your outside offer.
        </div>
        <div className="grid-row-title">
          <em>Paths:</em>
        </div>
        <div>
          Departures and retentions
        </div>
      </div>
      <p>Note: This question excludes those who did not receive counteroffers.</p>

      <h4>Departures</h4>
      {transformedResponse && transformedResponse.institution.departure &&
        <>
          {transformedResponse.institution.departure.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.departure.map((response: string, i: number) =>
                <li key={i}>{cleanString(response)}</li>
              )}
            </ul>
          }
        </>
      }

      <h4>Retentions</h4>
      {transformedResponse && transformedResponse.institution.retention &&
        <>
          {transformedResponse.institution.retention.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponse.institution.retention.map((response: string, i: number) =>
                <li key={i}>{cleanString(response)}</li>
              )}
            </ul>
          }
        </>
      }
      
    </>
  )
}

export default Subsection8;