import { formatLabels } from "../utils";
export interface Categories {
    exit_status: string[];
    gender: string[];
    race_ethnicity: string[];
    tenure_status: string[];
    discipline: string[];
}

export const getModifedCategoriesVarNames = (
    total?: boolean, 
    overallTotalOnly?: boolean
) => {
    const exitStatusCategories = Object.keys(retentionCategoriesWithTotalMap);
    const genderCategories = Object.keys(genderCategoriesMap);
    const raceEthnicityCategories = Object.keys(raceEthnicityCategoriesMap);
    const tenureStatusCategories = Object.keys(tenureStatusCategoriesMap);
    const disciplineCategories = Object.keys(disciplineCategoriesMap);

    if (overallTotalOnly) {
        return {
            exit_status: exitStatusCategories,
            gender: genderCategories.slice(0, -1),
            race_ethnicity: raceEthnicityCategories.slice(0, -1),
            tenure_status: tenureStatusCategories.slice(0, -1),
            discipline: disciplineCategories.slice(0, -1)
        }
    }

    if (total) {
        return {
            exit_status: exitStatusCategories,
            gender: genderCategories,
            race_ethnicity: raceEthnicityCategories,
            tenure_status: tenureStatusCategories,
            discipline: disciplineCategories
        }
    }

    return {
        exit_status: exitStatusCategories.slice(0, -1),
        gender: genderCategories.slice(0, -1),
        race_ethnicity: raceEthnicityCategories.slice(0, -1),
        tenure_status: tenureStatusCategories.slice(0, -1),
        discipline: disciplineCategories.slice(0, -1)
    }
}

export const moveLastElementToFront = (arr: string[]) => {
    const arrCopy = [...arr];
    const lastElement = arrCopy.pop();
    if (lastElement) {
        arrCopy.unshift(lastElement);
    }
    return arrCopy;
}

export const makeOverallArray = (arr: string[]) => {
    arr = ["Overall"].concat(arr);
    return arr;
}


export const getModifedCategories = (type?: string) => {
    const exitStatusCategories = Object.values(retentionCategoriesWithTotalMap);
    const genderCategories = Object.values(genderCategoriesMap);
    const raceEthnicityCategories = Object.values(raceEthnicityCategoriesMap);
    const tenureStatusCategories = Object.values(tenureStatusCategoriesMap);
    const disciplineCategories = Object.values(disciplineCategoriesMap);

    let result: Categories = {
        exit_status: exitStatusCategories,
        gender: genderCategories,
        race_ethnicity: raceEthnicityCategories,
        tenure_status: tenureStatusCategories,
        discipline: disciplineCategories
    }

    if (type === "The Transition" || type === "Preemptive Retention" || type === "No Total" || type === "Overall Total Only") {
        result = {
            exit_status: exitStatusCategories.slice(0, -1),
            gender: genderCategories.slice(0, -1),
            race_ethnicity: raceEthnicityCategories.slice(0, -1),
            tenure_status: tenureStatusCategories.slice(0, -1),
            discipline: disciplineCategories.slice(0, -1)
        }
    }

    let totalFirstResult = {
        exit_status: (moveLastElementToFront(exitStatusCategories)),
        gender: moveLastElementToFront(genderCategories),
        race_ethnicity: moveLastElementToFront(raceEthnicityCategories),
        tenure_status: moveLastElementToFront(tenureStatusCategories),
        discipline: moveLastElementToFront(disciplineCategories)
    }

    if (type === "Total First") {
        result = {
            ...totalFirstResult,
            exit_status: ["Total", "Departure", "Retention"]
        }
    }

    if (type === "Average Rank") {
        result = {
            ...totalFirstResult,
            exit_status: ["Overall", "Departure", "Retention"]
        }
    }

    if (type === "Overall Total Only") {
        result = {
            ...result,
            exit_status: ["Departure", "Retention", "Total"]
        }
    }

    if (type === "The Transition") {
        result = {
            ...result,
            exit_status: ["Departure"]
        }
    }


    if (type === "Preemptive Retention") {
        result = {
            ...result,
            exit_status: ["Preemptive Retention"]
        }
    }

    if (!type) {
        result = {
            ...result,
            exit_status: ["Departure", "Retention", "Total"]
        }
    }

    return result;
}

export const retentionCategoriesMap: { [key: string]: string } = {
    departure: "Departure",
    retention: "Retention",
    preemptive_retention: "Preemptive Retention",
}

export const retentionCategoriesWithTotalMap: { [key: string]: string } = {
    departure: "Departure",
    retention: "Retention",
    preemptive_retention: "Preemptive Retention",
    total: "Total"
}

export const retentionCategoriesWithOverallMap: { [key: string]: string } = {
    departure: "Departure",
    retention: "Retention",
    preemptive_retention: "Preemptive Retention",
    overall: "Total"
}

export const disciplineCategoriesMap: { [key: string]: string } = {
    stem: "STEM",
    social_sciences: "Social Sciences",
    humanities: "Humanities",
    professions_and_other: "Professions And Other",
    unknown_discipline: "Unknown Discipline",
    total: "Total"
}

export const tenureStatusCategoriesMap: { [key: string]: string } = {
    tenured: "Tenured",
    pre_tenure: "Pre Tenure",
    non_tenure_track: "Non Tenure Track",
    unknown_tenure: "Unknown Tenure",
    total: "Total"
}

export const genderCategoriesMap: { [key: string]: string } = {
    man: "Man/Trans Man",
    woman: "Woman/Trans Woman",
    other_or_unknown_gender: "Gender Other/Unknown",
    total: "Total"
}

export const raceEthnicityCategoriesMap: { [key: string]: string } = {
    white_non_hispanic: "White, non-Hispanic",
    color_and_other: "Faculty of color and other",
    other_unknown_race: "Race/Ethnicity Unknown",
    total: "Total"
}

export const getNameFromVariable = (category: string, name: string) => {
    let readableName = "";

    if (category === "exit_status") {
        readableName = retentionCategoriesWithTotalMap[name];
    }
    if (category === "gender") {
        readableName = genderCategoriesMap[name];
    }

    if (category === "discipline") {
        readableName = disciplineCategoriesMap[name];
    }

    if (category === "tenure_status") {
        readableName = tenureStatusCategoriesMap[name];
    }

    if (category === "race_ethnicity") {
        readableName = raceEthnicityCategoriesMap[name];
    }

    if (name === "overall") {
        return "Total";
    }

    if (readableName === undefined) {
        return formatLabels(name);
    }
    return readableName;
}
