import { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import Introduction from "./Introduction";
import Time from "./Subsection1";
import Motivation from "./Subsection2";
import ApplicationsAndOffers from "./Subsection3";
import InitialContact from "./Subsection4";
import InitialContactResponses from "./Subsection4b";
import BreadcrumbNav from "../../navigation/Breadcrumb";
import BottomNavigation from "../../navigation/BottomNavigation";

interface SearchProps {
  toggleSection: (section: string, open?: boolean) => void;
}

const Search: FC<SearchProps> = ({ toggleSection }) => {
  const { subsection } = useParams();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [subsection]);

  useEffect(() => {
    toggleSection("The Search", true);
  }, [toggleSection])

  useEffect(() => {
    if (subsection === "InitialContact")
      toggleSection("Search/Initial Contact", true);
  }, [toggleSection, subsection])


  return (
    <>
      <BreadcrumbNav section="Search" subsection={subsection} />

      <h1>The Search</h1>
      {subsection === "Introduction" && <Introduction />}
      {subsection === "Time" && <Time />}
      {subsection === "Motivation" && <Motivation />}
      {subsection === "ApplicationsAndOffers" && <ApplicationsAndOffers />}
      {subsection === "InitialContact" && <InitialContact />}
      {subsection === "ResponsesToOtherPromptsInitialContact" && <InitialContactResponses />}

      <BottomNavigation />
    </>
  )
}

export default Search;