import { useState, useEffect, useRef } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { NestedResponseData } from "../../models/responseData";
import { convertToPercentString, determineNdisplay } from "../../utils/utils";
import FilterDropdown from "../../components/FilterDropdown";
import TableToCSVConverter from "../../utils/csvDownload/TableToCsvConverter";
import { useParams } from "react-router-dom";
import PageLoader from "../../components/PageLoader";
import { Categories, getModifedCategoriesVarNames, getNameFromVariable } from "../../utils/legendMapping/categoryLegend";
import { determineResponseTypeNestedResponseData } from "../../utils/determineResponseType";


const ResponseRates = () => {
  const tableRef = useRef<HTMLTableElement>(null);
  const [selectedFilter, setSelectedFilter] = useState("exit_status");
  const [tableResponse, setTableResponse] = useState<NestedResponseData | null>(null);
  const [pageLoading, setPageLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(true);
  const [table, setTable] = useState<HTMLTableElement | null>(null);

  let defaultCategories = getModifedCategoriesVarNames(false, true);
  const [categories, setCategories] = useState(defaultCategories["exit_status"]);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {

    setPageLoading(true);
    const getDataTable = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `appendix/subsection9/?report_year=${year}&group_by=${selectedFilter}`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setTableResponse(data);
        setPageLoading(false);
        setCategories(defaultCategories[selectedFilter as keyof Categories]);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataTable();
  }, [getAccessTokenSilently, year, selectedFilter]);

  useEffect(() => {
    if (tableRef.current) {
      setTable(tableRef.current);
    }
  }, [tableResponse, selectedFilter]);

  return (
    <>
      <h2>Response rates</h2>
      <p>
        What are the population and respondent characteristics of your university and of the comparison cohort?
      </p>
      <h4>Respondent Counts</h4>
      <FilterDropdown setSelectedFilter={setSelectedFilter} />

      {pageLoading && <PageLoader />}
      {!pageLoading && tableResponse &&
        <>
          <table ref={tableRef}>
            <thead>
              <tr>
                <th></th>
                <th colSpan={5}>Your Institution</th>
                <th colSpan={5}>Cohort</th>
              </tr>
              <tr className="column-headers">
                <th className="row-header"></th>
                <th>#</th>
                <th>%</th>
                <th># Respon&shy;dents</th>
                <th>% Respon&shy;dents</th>
                <th className="row-split">Response Rates</th>
                <th>#</th>
                <th>%</th>
                <th># Respon&shy;dents</th>
                <th>% Respon&shy;dents</th>
                <th>Response Rates</th>
              </tr>
            </thead>
            <tbody>
              {
                categories.map((category) => {
                  const readableName = getNameFromVariable(selectedFilter, category)
                  let rowClassName = "";
                  if (category === "total") {
                    rowClassName = "grand-total-row"
                  }
                  return (
                    <tr className={rowClassName} key={`${category}`}>
                      <th className="row-header">{readableName}</th>
                      <td>{determineNdisplay(tableResponse.institution[category].total, false, true)}</td>
                      <td>{convertToPercentString(tableResponse.institution[category].total, tableResponse.institution.total.total, true, true)}</td>
                      <td>{determineNdisplay(tableResponse.institution[category].respondent)}</td>
                      <td>{convertToPercentString(tableResponse.institution[category].respondent, tableResponse.institution.total.respondent)}</td>
                      <td className="row-split">{convertToPercentString(tableResponse.institution[category].respondent, tableResponse.institution[category].total)}</td>

                      <td>{determineNdisplay(tableResponse.cohort[category].total, false, true)}</td>
                      <td>{convertToPercentString(tableResponse.cohort[category].total, tableResponse.cohort.total.total, true, true)}</td>
                      <td>{determineNdisplay(tableResponse.cohort[category].respondent)}</td>
                      <td>{convertToPercentString(tableResponse.cohort[category].respondent, tableResponse.cohort.total.respondent)}</td>
                      <td>{convertToPercentString(tableResponse.cohort[category].respondent, tableResponse.cohort[category].total)}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <TableToCSVConverter
            table={table}
            filename="Appendix_9_Response_rates"
            loading={tableLoading}
            setLoading={setTableLoading}
          />
        </>
      }


    </>
  )
}

export default ResponseRates;