const Subsection2 = () => {
    return (
        <>
            <h3>Administration</h3>
            <p>
                Subjects first received a message about the survey from a senior administrator (e.g., provost, vice provost) at
                their institution. They subsequently received an email from COACHE inviting them to complete the survey.
                Over the course of the survey administration period—typically about six weeks—several automated reminders
                were sent to those who had not completed the survey. Participants accessed a secure web server through their
                own unique link provided by COACHE and, agreeing to an informed consent statement, responded to a series
                of multiple-choice and open-ended questions.
            </p>
            
        </>
    )
}

export default Subsection2;