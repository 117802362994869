const Subsection5 = () => {
    return (
        <>
            <h4>How to contact COACHE</h4>
            <p>
                Any requests for additional details, questions and comments about this report should be directed to COACHE at <a id="email-link" href="mailto: coache_exit@gse.harvard.edu">coache_exit@gse.harvard.edu</a>
            </p>
            
        </>
    )
}

export default Subsection5;