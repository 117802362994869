import { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import * as Plot from "@observablehq/plot";
import { getProtectedResource } from "../../services/apiRequest.service";
import { SingularDataResponse, NestedResponseData } from "../../models/responseData";
import { roundNumber } from "../../utils/utils";
import TableToCSVConverter from "../../utils/csvDownload/TableToCsvConverter";
import PageLoader from "../../components/PageLoader";
import { retentionCategoriesWithOverallMap } from "../../utils/legendMapping/categoryLegend";
import { 
  determineResponseTypeNestedResponseData, determineResponseTypeSingularResponseData 
} from "../../utils/determineResponseType";

interface TransformedResponseA {
  label: string;
  value: number;
}

const AcademicAnalytics = () => {
  const tableRef = useRef<HTMLTableElement>(null);
  const plotContainerRef = useRef<HTMLDivElement | null>(null);
  const [responseA, setResponseA] = useState<SingularDataResponse | null>(null);
  const [insufficientNsectionA, setInsufficientNsectionA] = useState(false);
  const [responseB, setResponseB] = useState<NestedResponseData | null>(null);
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState<HTMLTableElement | null>(null);

  const { getAccessTokenSilently } = useAuth0();
  const { year } = useParams();

  useEffect(() => {

    const getDataSectionA = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrlA = `appendix/subsection7a/?report_year=${year}`;

      let { data, error } = await getProtectedResource(accessToken, requestUrlA);

      if (data && determineResponseTypeSingularResponseData(data)) {
        setResponseA(data);
        setLoading(false);
        if (Object.keys(data.institution).length === 0) {
          setInsufficientNsectionA(true);
        }
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataSectionB = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrlB = `appendix/subsection7b/?report_year=${year}`;

      let { data, error } = await getProtectedResource(accessToken, requestUrlB);

      if (data && determineResponseTypeNestedResponseData(data)) {
        setResponseB(data);
      }
      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataSectionA();
    getDataSectionB();
  }, [getAccessTokenSilently, year])

  useEffect(() => {
    if (tableRef.current) {
      setTable(tableRef.current);
    }
  }, [responseB]);

  useEffect(() => {
    let chart;
    let transformedResponseA: TransformedResponseA[];

    if (responseA) {
      transformedResponseA = Object.entries(responseA.institution).map(([label, value]) => {
        if (label.includes(".0")) {
          label = label.split(".")[0];
        }
        if (label === "not_answered") {
          label = "Not Answered"
        }
        return { label, value }
      })

      if (plotContainerRef.current) {
        chart = Plot.plot({
          marks: [
            Plot.barX(transformedResponseA, { x: "value", y: "label", fill: "#565656", insetTop: 0.2, insetBottom: 0.2 }),
            Plot.text(transformedResponseA, { x: "value", y: "label", text: d => d.value, dx: 15 })
          ],
          marginLeft: 100,
          x: { line: true, label: null, nice: true },
          y: { line: true, label: null, type: "band" }
        });
        plotContainerRef.current.innerHTML = "";
        plotContainerRef.current.appendChild(chart);
      }
    }
  }, [responseA])

  return (
    <>
      <h2>Academic analytics</h2>
      <p>
        What Academic Analytics profiles of eligible faculty?
        (<em>Available only to institutions who provided <span className="red-variable-name">aa_quintile</span> and <span className="red-variable-name">aa_fspi</span> to COACHE.</em>)
      </p>

      <h4>a. aa_quintile</h4>
      {!responseA && <PageLoader />}
      {!insufficientNsectionA && responseA && <div ref={plotContainerRef} />}

      <h4>b. aa_fspi</h4>
      {!responseB && <PageLoader />}

      {responseB &&
        <>
          <table ref={tableRef}>
            <thead>
              <tr>
                <th></th>
                <th colSpan={7}>Your Institution</th>
              </tr>
            </thead>
            <thead className="column-headers">
              <tr>
                <th className="row-header"></th>
                <th>Valid n</th>
                <th>Mean</th>
                <th>Median</th>
                <th>Min</th>
                <th>Max</th>
                <th>SD</th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(retentionCategoriesWithOverallMap).map((retentionStatus, i) => {
                let rowClassName = "";
                if (retentionStatus === "overall") {
                  rowClassName = "grand-total-row"
                }

                let current;
                if (responseB.institution[retentionStatus]) {
                  current = responseB.institution[retentionStatus];
                }
                return (
                  <tr className={rowClassName} key={i}>
                    <th className="row-header">{retentionCategoriesWithOverallMap[retentionStatus]}</th>
                    <td>{current ? current.count : ""}</td>
                    <td>{current && current.count ? roundNumber(current.mean) : ""}</td>
                    <td>{current && current.count ? roundNumber(current.median) : ""}</td>
                    <td>{current && current.count ? roundNumber(current.min) : ""}</td>
                    <td>{current && current.count ? roundNumber(current.max) : ""}</td>
                    <td>{current && current.count ? roundNumber(current.stdev, true) : ""}</td>
                  </tr>)
              }
              )}
            </tbody>
          </table>
          <TableToCSVConverter
            table={table}
            filename="Appendix_7b_Academic_analytics"
            loading={loading}
            setLoading={setLoading}
          />
        </>
      }


    </>
  )
}

export default AcademicAnalytics;