import { Link, useParams, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { getNextSection, getPreviousSection, getFirstSection, getLastSection } from "./FileStructure";
import { formatBreadcrumbLabel } from "../utils/navUtils";

const formatTitle = (title: string) => {
  if (title.includes("/")) {
    title = title.split("/").join(": ");
  }
  return title;
}

const BottomNavigation = () => {
  const { reportType, year } = useParams();

  const location = useLocation();
  const splitPath = location.pathname.split("/");
  const path = splitPath[1] + "/" + splitPath[2];

  const sectionTitle = splitPath[splitPath.length - 2];
  const subsectionTitle = splitPath[splitPath.length - 1];

  const firstSection = getFirstSection();
  const lastSection = getLastSection();

  return (
    <div className="bottom-nav">
      {subsectionTitle !== firstSection &&
        <>
          <div className="bottom-nav-box">
            <Link className="route-links" to={`/${path}/${reportType}/${year}/${getPreviousSection(sectionTitle, subsectionTitle)}`}>
              <div className="bottom-nav-direction">
                <ArrowBackIcon />
                <span>Previous</span>
              </div>
              <div className="bottom-nav-section-title">
                {formatBreadcrumbLabel(formatTitle(getPreviousSection(sectionTitle, subsectionTitle)))}
              </div>
            </Link>
          </div>
        </>
      }
      {subsectionTitle !== lastSection &&
        <>
          <div className="bottom-nav-box">
            <Link className="route-links" to={`/${path}/${reportType}/${year}/${getNextSection(sectionTitle, subsectionTitle)}`}>
              <div className="bottom-nav-direction">
                <span>Next</span>
                <ArrowForwardIcon />
              </div>
              <div className="bottom-nav-section-title">
                {formatBreadcrumbLabel(formatTitle(getNextSection(sectionTitle, subsectionTitle)))}
              </div>
            </Link>
          </div>
        </>
      }
    </div>
  )
}

export default BottomNavigation;