import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { getProtectedResource } from "../../services/apiRequest.service";
import { TextResponse } from "../../models/responseData";
import { cleanString } from "../../utils/formatString";
import { determineResponseTypeTextResponse } from "../../utils/determineResponseType";

const Subsection2 = () => {
  const [transformedResponseA, setTransformedResponseA] = useState<TextResponse | null>(null);
  const [transformedResponseB, setTransformedResponseB] = useState<TextResponse | null>(null);


  const { getAccessTokenSilently } = useAuth0();
  const { year, institution } = useParams();

  useEffect(() => {
    const getDataA = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section7/subsection2/other/section1?report_year=${year}&group_by=exit_status`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTextResponse(data)) {
        setTransformedResponseA(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getDataB = async () => {
      const accessToken = await getAccessTokenSilently();
      const requestUrl = `section7/subsection2/other/section2?report_year=${year}&group_by=exit_status`;
      const { data, error } = await getProtectedResource(accessToken, requestUrl);

      if (data && determineResponseTypeTextResponse(data)) {
        setTransformedResponseB(data);
      }

      if (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataA();
    getDataB();
  }, [getAccessTokenSilently, year]);

  return (
    <>
      <h2>7.2 Treatment of faculty after departure intentions are known (Open-text Response)</h2>
      <h3>Were faculty treated well in their transitions out of your institution?</h3>

      <div className="grid">
        <div className="grid-row-title">
          <em>Prompt:</em>
        </div>
        <div>
          In what ways, if any, was <span className="red-variable-name">{institution}</span> supportive of you during your transition? How could <span className="red-variable-name">{institution}</span> have better supported you, if at all, during your transition?
        </div>
        <div className="grid-row-title">
          <em>Paths:</em>
        </div>
        <div>
          Departures
        </div>
      </div>

      <h3>In what ways, if any, was <span className="red-variable-name">{institution}</span> supportive of you during your transition?</h3>
      <h4>Departures</h4>
      {transformedResponseA && transformedResponseA.institution.departure &&
        <>
          {transformedResponseA.institution.departure.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponseA.institution.departure.map((response: string, i: number) =>
                <li key={i}>{cleanString(response)}</li>
              )}
            </ul>
          }
        </>
      }

      <h3>How could <span className="red-variable-name">{institution}</span> have better supported you, if at all, during your transition?</h3>
      <h4>Departures</h4>
      {transformedResponseB && transformedResponseB.institution.departure &&
        <>
          {transformedResponseB.institution.departure.length === 0 ? <>No text responses to display.</> :
            <ul>
              {transformedResponseB.institution.departure.map((response: string, i: number) =>
                <li key={i}>{cleanString(response)}</li>
              )}
            </ul>
          }
        </>
      }

      
    </>
  )
}

export default Subsection2;