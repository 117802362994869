import LoginButton from "../components/Login";

const LoginPage = () => {


    return (
        <>
            <div className="login-banner">
                <h1>Welcome to COACHE's Reporting Page</h1>
                <div>Please login to see your reports</div>
            </div>
            <div className="login-button">
                <LoginButton />
            </div>
        </>
    )
}

export default LoginPage;