import { FC, useRef, useEffect } from "react";
import * as Plot from "@observablehq/plot";
import { TransformedData } from "../../utils/transformDataUtils";
import { groupedBarChartColorScheme } from "../shared/colorScheme";
import { defaultXscaleBarChart, plotStyle } from "../shared/observablePlotUtils";

interface DoubleBarChartNestedProps {
    data: TransformedData[];
    selectedFilter: string;
    validCategoriesForChart: any;
}

const DoubleBarChartNested: FC<DoubleBarChartNestedProps> = ({ 
    data, 
    selectedFilter, 
    validCategoriesForChart
}) => {
    const plotContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
        let chart;
        let width = 900;

        let fillDomain = ["Departure", "Retention"];

        if (plotContainerRef.current && data) {
            plotContainerRef.current.innerHTML = "";

            for (const category of validCategoriesForChart) {
                chart = Plot.plot({
                    marks: [
                        Plot.barX(data.filter((item) => item.category === category), {
                            x: "value",
                            y: "retention_status",
                            fx: "type",
                            fy: "description",
                            fill: "retention_status",
                            sort: {
                                fx: { value: "x", order: null },
                                y: { value: "y", order: null },
                                fy: { value: "y", order: null }
                            },
                            insetTop: 0.2,
                            insetBottom: 0.2,
                            stroke: groupedBarChartColorScheme[0],
                            strokeWidth: .75,
                        }),
                        Plot.text(data.filter((item) => item.category === category), {
                            text: d => {
                                if (d.value) {
                                    return `${Math.round(d.value * 10000) / 100}%`
                                }
                            },
                            x: "value",
                            y: "retention_status",
                            fx: "type",
                            fy: "description",
                            dx: 20,
                        }),
                        Plot.axisFx({
                            label: null,
                        }),
                        Plot.axisFy({
                            lineWidth: 10,
                            anchor: "left",
                            tickPadding: 10,
                            label: null,
                        }),
                        Plot.ruleX([0]),
                    ],
                    x: {
                        ...defaultXscaleBarChart,
                        nice: true
                    },
                    y: { label: null, axis: null },
                    title: category,
                    width: Math.max(width, 550),
                    marginLeft: 100,
                    color: {
                        type: "categorical",
                        legend: true,
                        domain: fillDomain,
                        range: groupedBarChartColorScheme,
                    },
                    style: plotStyle,
                });
                plotContainerRef.current.appendChild(chart);
            }
        }
    }, [data, selectedFilter, validCategoriesForChart])

    return (
        <div ref={plotContainerRef} />
    )
}

export default DoubleBarChartNested;